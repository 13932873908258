import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { checkDocFlowUrl, checkDocUrl, checkEdoUrl } from 'urls';

import { initialEdo } from 'shared/const/initial-state.const';
import { TEdo } from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import { logOutUser } from './auth.slice';
import { AppDispatch } from './index';

const initialState: TEdo = { ...initialEdo };

/** Способы обмена документами */
const EXCHANGE_TYPES_DTO = {
  edo: 1,
  paper: 2,
};

const edoSlice = createSlice({
  name: 'edo',
  initialState,
  reducers: {
    setEdoFlow(state) {
      state.flow.isEdoFlowRequest = true;
      state.flow.isEdoFlowFailed = false;
    },
    setEdoFlowSuccess(state, action: PayloadAction<boolean>) {
      state.flow.isEdoFlow = action.payload;
      state.flow.isEdoFlowRequest = false;
    },
    setEdoFlowFailed(state) {
      state.flow.isEdoFlowFailed = true;
      state.flow.isEdoFlowRequest = false;
    },
    setEdoRegistered(state) {
      state.check.isEdoRegisteredRequest = true;
      state.check.isEdoRegisteredFailed = false;
    },
    setEdoRegisteredSuccess(state, action: PayloadAction<boolean>) {
      state.check.isEdoRegistered = action.payload;
      state.check.isEdoRegisteredRequest = false;
    },
    setEdoRegisteredFailed(state) {
      state.check.isEdoRegisteredFailed = true;
      state.check.isEdoRegisteredRequest = false;
    },
    setContinueNoEdoRegistered(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.check.isContinueNoEdoRegistered = action.payload;
    },
    setOfferAccepted(state) {
      state.offer.isOfferAcceptedRequest = true;
      state.offer.isOfferAcceptedFailed = false;
    },
    setOfferAcceptedSuccess(state, action: PayloadAction<boolean>) {
      state.offer.isOfferAccepted = action.payload;
      state.offer.isOfferAcceptedRequest = false;
    },
    setOfferAcceptedFailed(state) {
      state.offer.isOfferAcceptedFailed = true;
      state.offer.isOfferAcceptedRequest = false;
    },
    setIsShowReady(state, action: PayloadAction<boolean>) {
      state.offer.isShowReady = action.payload;
    },
  },
});

export const checkDocFlow = createAsyncThunk<
  void,
  undefined,
  { dispatch: AppDispatch }
>('edo/checkDocFlow', async (_, { dispatch, rejectWithValue }) => {
  const orgId = multiLoginStorage.getOrgId();
  dispatch(setEdoFlow());
  try {
    if (orgId) {
      const { data } = await httpClient.get(checkDocFlowUrl(orgId));
      const isEdoFlow = data === EXCHANGE_TYPES_DTO.edo;
      dispatch(setEdoFlowSuccess(isEdoFlow));
      return data;
    }
  } catch (error) {
    const err = error as IError;

    dispatch(setEdoFlowFailed());

    if (err?.response?.status === 401) {
      dispatch(logOutUser());
    }

    return rejectWithValue(err.response.data.message);
  }
});

export const checkEdo = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch }
>('edo/checkEdo', async (_, { dispatch, rejectWithValue }) => {
  const orgId = multiLoginStorage.getOrgId();
  dispatch(setEdoRegistered());
  try {
    if (orgId) {
      const { data } = await httpClient.get(checkEdoUrl(orgId));
      dispatch(setEdoRegisteredSuccess(data));
      dispatch(setContinueNoEdoRegistered(data));
      return data;
    }
  } catch (error) {
    const err = error as IError;

    dispatch(setEdoRegisteredFailed());

    if (err?.response?.status === 401) {
      dispatch(logOutUser());
    }

    return rejectWithValue(err.response.data.message);
  }
});

export const checkOffer = createAsyncThunk<
  void,
  undefined,
  { dispatch: AppDispatch }
>('edo/checkOffer', async (_, { dispatch, rejectWithValue }) => {
  const orgId = multiLoginStorage.getOrgId();
  dispatch(setOfferAccepted());
  try {
    if (orgId) {
      const { data } = await httpClient.get(
        `${checkDocUrl(orgId)}?docType=400`,
      );
      dispatch(setOfferAcceptedSuccess(data));
      return data;
    }
  } catch (error) {
    const err = error as IError;

    dispatch(setOfferAcceptedFailed());

    if (err?.response?.status === 401) {
      dispatch(logOutUser());
    }

    return rejectWithValue(err.response.data.message);
  }
});

export const {
  setEdoFlow,
  setEdoFlowSuccess,
  setEdoFlowFailed,
  setEdoRegistered,
  setEdoRegisteredSuccess,
  setEdoRegisteredFailed,
  setContinueNoEdoRegistered,
  setOfferAccepted,
  setOfferAcceptedSuccess,
  setOfferAcceptedFailed,
  setIsShowReady,
} = edoSlice.actions;

export default edoSlice.reducer;
