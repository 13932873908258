import { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, getOrgState } from 'store';
import { clearDocs, getDocs } from 'store/docs.slice';
import { clearOrg, getOrg } from 'store/org.slice';

import ContentLoader from 'components/content-loader/content-loader';
import CustomCheckbox from 'components/custom-checkbox/custom-checkbox';
import CustomInput from 'components/custom-input/custom-input';
import Documents from 'components/documents/documents';

import { tokens } from 'shared/utils/tokens.util';

import styles from './profile.module.css';

export const Profile: FC = memo(() => {
  const org = useSelector(getOrgState);
  const isAuth = !!tokens.getAccess();
  const [isAddressEq, setAsAddressEq] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (org.info) {
      if (org.info.legalAddressId || org.info.postalAddressId) {
        setAsAddressEq(
          org.info.legalAddressId === org.info.postalAddressId,
        );
      } else {
        setAsAddressEq(
          org.info.legalAddress === org.info.postalAddress,
        );
      }

      dispatch(getDocs());
    }
  }, [org, dispatch]);

  useEffect(() => {
    dispatch(clearDocs());
    dispatch(clearOrg());
    dispatch(getOrg());
  }, [dispatch]);

  return (
    <div className="profile mt-4">
      {isAuth && (
        <div className="container">
          {org.orgRequest && (
            <ContentLoader placeholderType="profile" />
          )}

          {!org.orgRequest && (
            <div>
              <h1 className={styles.header}>Профиль организации</h1>

              {!!org.info?.longName && (
                <div className="row">
                  <div className="col">
                    <CustomInput
                      testId="profile-input-1"
                      label="Полное название организации"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org.info?.longName}
                      margin="mt"
                    />
                  </div>
                </div>
              )}

              <div className="row">
                {!!org.info?.shortName && (
                  <div className="col-12">
                    <CustomInput
                      testId="profile-input-2"
                      label="Краткое название организации"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org.info?.shortName}
                      margin="mt"
                    />
                  </div>
                )}
              </div>

              <div className="row">
                {!!org.info?.inn && (
                  <div className="col-6 col-md-3">
                    <CustomInput
                      testId="profile-input-3"
                      label="ИНН"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org.info?.inn}
                      margin="mt"
                    />
                  </div>
                )}

                {!!org.info?.ogrn && (
                  <div className="col-6 col-md-3">
                    <CustomInput
                      testId="profile-input-4"
                      label="ОГРН"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org.info?.ogrn}
                      margin="mt"
                    />
                  </div>
                )}

                {!!org.info?.kpp && (
                  <div className="col-6 col-md-3">
                    <CustomInput
                      testId="profile-input-5"
                      label="КПП"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org.info?.kpp}
                      margin="mt"
                    />
                  </div>
                )}

                {!!org.info?.partnerId && (
                  <div className="col-6 col-md-3">
                    <CustomInput
                      testId="profile-input-6"
                      label="ID"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org.info?.partnerId}
                      margin="mt"
                    />
                  </div>
                )}
              </div>

              {!!org.info?.legalAddress && (
                <div className="row">
                  <div className="col">
                    <CustomInput
                      testId="profile-input-7"
                      label="Юридический адрес"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org.info?.legalAddress}
                      margin="mt"
                    />
                  </div>
                </div>
              )}

              {!!org.info?.postalAddress && (
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-8">
                    <CustomInput
                      testId="profile-input-8"
                      label="Почтовый адрес"
                      type="text"
                      theme="edo"
                      disabled
                      defaultValue={org.info?.postalAddress}
                      margin="mt"
                    />
                  </div>

                  <div className="col-12 col-md-6 col-lg-4">
                    <CustomCheckbox
                      label="Почтовый адрес совпадает с юридическим"
                      disabled
                      checked={isAddressEq}
                      margin="mt"
                    />
                  </div>
                </div>
              )}

              <div className={styles.separator} />

              <h1 className={styles.header}>Документы</h1>

              {!!org && <Documents />}
            </div>
          )}
        </div>
      )}

      {!isAuth && (
        <div className={styles['no-auth']}>Вы не авторизованы</div>
      )}
    </div>
  );
});

Profile.displayName = 'Profile';
