import { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppDispatch,
  getDeliveryState,
  getModals,
  getMultiLogin,
  getOrder,
} from 'store';
import { setOrderLoaderFlag, setStartOrder } from 'store/order.slice';
import { addAlert } from 'store/ui.slice';
import { currency } from 'utils';

import CustomBtn from 'components/custom-btn/custom-btn';
import OrderDelivery from 'components/order-delivery/order-delivery';
import OrderItems from 'components/order-items/order-items';

import { CONTENT, ONE_MINUTE } from 'shared/const';
import { useAuth } from 'shared/hooks/use-auth';
import { tokens } from 'shared/utils/tokens.util';

import styles from './aside.module.scss';

const Aside: FC = memo(() => {
  const order = useSelector(getOrder);
  const { isTariffLoading } = useSelector(getDeliveryState);
  const { logins } = useSelector(getMultiLogin);
  const isAuth = tokens.getAccess();
  const { deliveryData } = useSelector(getDeliveryState);
  const { showModalInvoice } = useSelector(getModals);
  const auth = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const handleOrderLoaderFlagToggle = useCallback(
    (val: boolean) => {
      dispatch(setOrderLoaderFlag(val));
    },
    [dispatch],
  );

  const startOrderOn = useCallback(() => {
    if (!isAuth) {
      return auth?.logIn();
    }

    if (logins.length === 0) {
      return dispatch(
        addAlert({
          text: CONTENT.auth.login.zeroOrganizations,
          variant: 'warning',
          lifetime: ONE_MINUTE,
        }),
      );
    }

    handleOrderLoaderFlagToggle(true);
    dispatch(setStartOrder(true));
  }, [
    dispatch,
    handleOrderLoaderFlagToggle,
    isAuth,
    auth,
    logins.length,
  ]);

  const startOrderOff = useCallback(() => {
    dispatch(setStartOrder(false));
  }, [dispatch]);

  return (
    <aside className={`${styles.aside} d-none d-lg-block`}>
      <div className="aside-item mt-3">
        <OrderItems aside />
      </div>

      <div className="aside-delivery mt-3">
        <OrderDelivery
          aside
          startOrderOff={startOrderOff}
          onOrderLoaderFlagToggle={handleOrderLoaderFlagToggle}
        />

        {!!order.info?.totalPrice && (
          <div className={`${styles['total-price']} mt-3 pt-3`}>
            <div className={styles.total}>Итого:</div>
            {`${currency(
              order.info.totalPrice + deliveryData.deliveryPrice,
            )} ₽`}
          </div>
        )}

        <div className="mt-3 mb-3">
          <CustomBtn
            theme="edo"
            type="button"
            text={isAuth ? 'Заказать' : 'Авторизуйтесь'}
            onClick={startOrderOn}
            testId="products-btn-1"
            loading={
              order.orderLoaderFlag ||
              isTariffLoading ||
              showModalInvoice
            }
            isLink={false}
            customCls={null}
            disabled={false}
          />
        </div>
      </div>
    </aside>
  );
});

Aside.displayName = 'Aside';

export default Aside;
