import React, { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'store';
import { clearOrg } from 'store/org.slice';

import { Icon } from 'components/Icon';

import { TAuth } from 'shared/models/global-state.model';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import styles from './login-item.module.css';

interface ILoginItemProps {
  data: TAuth['multiLogin']['logins'][number];
  isInMenu: boolean;
  toggleMenu?: (e: React.MouseEvent) => void;
}

export const LoginItem: FC<ILoginItemProps> = memo(
  ({
    data: {
      orgNameCustom,
      orgName,
      orgInn,
      orgKpp,
      orgId,
      isEmployee,
    },
    isInMenu,
    toggleMenu,
  }) => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const handleClick = useCallback(
      (e: React.MouseEvent) => {
        multiLoginStorage.setOrgId(orgId);
        dispatch(clearOrg());
        navigate('/');
        toggleMenu && toggleMenu(e);
      },
      [navigate, orgId, toggleMenu, dispatch],
    );

    const loginStyle = useMemo(
      () => ({
        listItem: isInMenu
          ? styles['in-menu-list-item']
          : styles['list-item'],
        img: isInMenu ? styles['in-menu-img'] : styles.img,
        listItemContainer: isInMenu
          ? styles['in-menu-list-item-container']
          : styles['list-item-container'],
        itemName: isInMenu
          ? styles['in-menu-item-name']
          : styles['item-name'],
        listItemWrap: isInMenu
          ? styles['in-menu-list-item-wrap']
          : styles['list-item-wrap'],
        dot: isInMenu ? styles['in-menu-dot'] : styles.dot,
      }),
      [isInMenu],
    );

    return (
      <li className={loginStyle.listItem} onClick={handleClick}>
        <div className={loginStyle.img}>
          {isEmployee ? (
            <Icon name="newUser" width="20" height="20" />
          ) : (
            <Icon name="briefBag" width="20" height="20" />
          )}
        </div>

        <div className={loginStyle.listItemContainer}>
          <div className={loginStyle.itemName}>
            {orgNameCustom || orgName}
          </div>
          <div className={loginStyle.listItemWrap}>
            {orgNameCustom && (
              <>
                <p>{orgName}</p>
                <div className={loginStyle.dot} />
              </>
            )}
            <p>ИНН: {orgInn}</p>
            {orgKpp && (
              <>
                <div className={loginStyle.dot} />
                <p>КПП: {orgKpp}</p>
              </>
            )}
          </div>
          {isInMenu && <div className={styles.gradient} />}
        </div>
      </li>
    );
  },
);

LoginItem.displayName = 'LoginItem';
