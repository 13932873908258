import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { AppDispatch } from 'store';
import { logOutUser } from 'store/auth.slice';
import { addAlert, setShowModalInvoice } from 'store/ui.slice';
import { createPaymentsUrl, getPaymentStatus } from 'urls';

import { CONTENT, ONE_MINUTE } from 'shared/const';
import { TPayments } from 'shared/models/order.model';
import { TPayStatuses } from 'shared/models/payment.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

type TPaymentStatus = {
  orderId: number;
  paymentId: string;
  paymentStatus: TPayStatuses;
  paymentUrl: string;
};

export const usePayment = () => {
  const { orderId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentKey = searchParams.get('key');
  const orgId = multiLoginStorage.getOrgId();

  const dispatch: AppDispatch = useDispatch();

  const [status, setStatus] = useState<TPayStatuses | null>('NEW');
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(
    !!orderId,
  );

  const fetchStatus = useCallback(async () => {
    try {
      setIsStatusLoading(true);

      const { data } = await httpClient.get<TPaymentStatus>(
        getPaymentStatus(orgId, orderId || '', paymentKey || ''),
      );

      setStatus(data.paymentStatus);
    } catch (error) {
      const err = error as IError;
      setStatus(null);
      setIsStatusModalVisible(false);

      if (err?.response?.status === 401) {
        return dispatch(logOutUser());
      }

      if (err?.response?.status === 403) {
        return setError(CONTENT.http.blocked);
      }

      if (err?.response?.status === 404) {
        return dispatch(
          addAlert({
            text: 'Данные о статусе платежа не найдены',
            variant: 'danger',
            lifetime: ONE_MINUTE,
          }),
        );
      }

      dispatch(
        addAlert({
          text: 'Ошибка получения статуса платежа',
          variant: 'danger',
          lifetime: ONE_MINUTE,
        }),
      );
    } finally {
      setIsStatusLoading(false);
    }
  }, [dispatch, orderId, orgId, paymentKey]);

  const handlePayClick = useCallback(
    async (orderId: number) => {
      setError(null);
      searchParams.delete('payment');
      setSearchParams(searchParams);

      try {
        const { data } = await httpClient.post<TPayments>(
          createPaymentsUrl(orgId, orderId),
          { email },
        );
        window.location.href = data.paymentUrl;
        dispatch(setShowModalInvoice(false));
      } catch (error) {
        const err = error as IError;

        switch (err?.response.status) {
          case 409:
            setError(CONTENT.order.orderHasBeenPaid);
            dispatch(
              addAlert({
                text: CONTENT.order.orderHasBeenPaid,
                variant: 'danger',
                lifetime: ONE_MINUTE,
              }),
            );
            break;

          case 500:
            dispatch(
              addAlert({
                text: CONTENT.http.serverError,
                variant: 'danger',
                lifetime: ONE_MINUTE,
              }),
            );
            break;

          default:
            dispatch(
              addAlert({
                text: CONTENT.order.paymentRequestError,
                variant: 'danger',
                lifetime: ONE_MINUTE,
              }),
            );
            break;
        }
      }
    },
    [dispatch, email, orgId, searchParams, setSearchParams],
  );

  return {
    status,
    email,
    setEmail,
    error,
    setError,
    isStatusLoading,
    urlOrderID: orderId,
    isStatusModalVisible,
    setIsStatusModalVisible,
    fetchStatus,
    handlePayClick,
  };
};
