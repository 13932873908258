import classNames from 'classnames/bind';
import React, {
  FC,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { getMenu, getMultiLogin, getOrder, getOrgState } from 'store';

import { Icon } from 'components/Icon';

import { useAuth } from 'shared/hooks/use-auth';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';
import { tokens } from 'shared/utils/tokens.util';

import styles from './auth-menu.module.css';

interface AuthMenuProps {
  logo: ReactNode;
  toggleMenu: (e: React.MouseEvent) => void;
}

const cn = classNames.bind(styles);
const SCREEN_WIDTH = 991;

const AuthMenu: FC<AuthMenuProps> = memo(({ logo, toggleMenu }) => {
  const [isDesktopScreen, setIsDesktopScreen] = useState(
    window.innerWidth > SCREEN_WIDTH,
  );
  const order = useSelector(getOrder);
  const org = useSelector(getOrgState);
  const { logins } = useSelector(getMultiLogin);
  const { isShow } = useSelector(getMenu);
  const isAuth = !!tokens.getAccess();
  const orgId = multiLoginStorage.getOrgId();
  const auth = useAuth();

  const currentName = useMemo(() => {
    const currenLogin = logins.find((login) => login.orgId === orgId);
    return org.info
      ? org.info?.shortName ?? org.info?.longName
      : currenLogin?.orgNameCustom ?? currenLogin?.orgName;
  }, [org.info, logins, orgId]);

  const handleAuth = useCallback(() => {
    !isAuth && auth?.logIn();
  }, [isAuth, auth]);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      isDesktopScreen && !isAuth ? handleAuth() : toggleMenu(e);
    },
    [handleAuth, isDesktopScreen, isAuth, toggleMenu],
  );

  const currentIcon = useMemo(() => {
    return (
      <Icon
        name={isAuth ? 'orangeBox' : 'burger'}
        width="16"
        height="16"
      />
    );
  }, [isAuth]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopScreen(window.innerWidth > SCREEN_WIDTH);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={cn('auth-menu', { between: !isAuth })}
      id="profile-btn"
      onClick={handleClick}
    >
      <div className={styles.title}>
        {/** Вывод имени пользователя  */}
        {isAuth && (
          <p onClick={handleClick} id="profile-btn">
            {currentName}
          </p>
        )}

        {/** Вывод кнопки "Войти"  */}
        {!isAuth && (
          <>
            <button
              type="button"
              id="auth-btn"
              className="d-none d-lg-block"
              data-testid="auth-menu-click-1"
            >
              Войти
            </button>

            {isShow && (
              <button
                type="button"
                id="auth-btn"
                className="d-lg-none d-flex align-items-center"
                onClick={handleAuth}
                data-testid="auth-menu-click-2"
              >
                <Icon name="authLogoMob" width="14" height="16" />
                <span className={styles['menu-item']}>Войти</span>
              </button>
            )}
          </>
        )}
      </div>

      <button
        type="button"
        id="profile-btn"
        onClick={handleClick}
        className={styles.icon}
        data-testid="auth-menu-click-3"
      >
        {!!order.info?.products?.length && (
          <>
            <div className="d-none d-xl-block">
              <Icon name="orangeBox" width="16" height="16" />
            </div>
            <div className="d-xl-none">{currentIcon}</div>

            <span className={styles.counter}>
              {order.info.products.reduce(
                (sum: number, current) =>
                  sum + (current.quantity || 1),
                0,
              )}
            </span>
          </>
        )}

        {!order.info?.products?.length && (
          <>
            <div className="d-none d-xl-block">
              {logo as ReactNode}
            </div>

            <div className="d-xl-none">
              <Icon name="burger" width="16" height="16" />
            </div>
          </>
        )}
      </button>
    </div>
  );
});

AuthMenu.displayName = 'AuthMenu';

export default AuthMenu;
