import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, getOrder } from 'store';
import { logOutUser } from 'store/auth.slice';
import { addToOrders, setOrders } from 'store/order.slice';
import { addAlert } from 'store/ui.slice';
import { dateFormatter } from 'utils';

import { TData } from 'pages/orders';

import { PAGINATION_STEP } from 'shared/const/orders-pagination.const';
import { Order } from 'shared/models/order.model';
import httpClient from 'shared/utils/http-client/http-client';

import { IUseOrdersPagination } from './use-orders-pagination.hook';

interface IUseOrders {
  fetchOrdersInfo: (url?: string) => void;
  isOrdersLoading: boolean;
  setIsOrdersLoading: React.Dispatch<React.SetStateAction<boolean>>;
  filteredOrders: Order[];
  setOrderNum: React.Dispatch<React.SetStateAction<string>>;
  dateRange: TData[];
  setDateRange: React.Dispatch<React.SetStateAction<TData[]>>;
}

export const useOrders = (
  {
    paginationUrl,
    setIsLoadMoreDisabled,
    nextPaginationStep,
  }: IUseOrdersPagination,
  deliveryType: string | null,
): IUseOrders => {
  const { ordersHistory } = useSelector(getOrder, (prev, next) => {
    return prev.ordersHistory.length === next.ordersHistory.length;
  });
  const dispatch: AppDispatch = useDispatch();

  /** Orders Filter */
  const [orderNum, setOrderNum] = useState('');
  const [dateRange, setDateRange] = useState<TData[]>([]);

  const filteredOrders = useMemo(() => {
    if (!ordersHistory?.length) return [];

    let filteredArr = [...ordersHistory];

    if (orderNum !== '') {
      filteredArr = filteredArr.filter((item) =>
        String(item.orderId).includes(orderNum),
      );
    }

    if (dateRange.length !== 0) {
      filteredArr = filteredArr.filter(
        (item) =>
          dateFormatter(dateRange[0], 'yyyymmdd') <=
            dateFormatter(item.orderDate, 'yyyymmdd') &&
          dateFormatter(dateRange[1], 'yyyymmdd') >=
            dateFormatter(item.orderDate, 'yyyymmdd'),
      );
    }

    if (deliveryType !== null) {
      filteredArr = filteredArr.filter((item) =>
        String(item.deliveryTypeId).includes(deliveryType),
      );
    }

    return filteredArr;
  }, [dateRange, deliveryType, orderNum, ordersHistory]);

  /** Orders List */
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);

  const fetchOrdersInfo = useCallback(
    (url?: string) => {
      setIsOrdersLoading(true);

      httpClient
        .get<Order[]>(url || paginationUrl)
        .then(({ data }) => {
          if (nextPaginationStep === 0 && !url) {
            dispatch(setOrders(data));
          } else {
            dispatch(addToOrders(data));
          }

          if (data.length < PAGINATION_STEP) {
            setIsLoadMoreDisabled(true);
          }
        })
        .catch((error) => {
          setIsLoadMoreDisabled(true);

          if (error?.response?.status === 401) {
            return dispatch(logOutUser());
          }

          dispatch(
            addAlert({
              text: 'Ошибка получения списка заказов',
              variant: 'danger',
            }),
          );
        })
        .finally(() => {
          setIsOrdersLoading(false);
        });
    },
    [
      dispatch,
      nextPaginationStep,
      paginationUrl,
      setIsLoadMoreDisabled,
    ],
  );

  return {
    fetchOrdersInfo,
    isOrdersLoading,
    setIsOrdersLoading,
    filteredOrders,
    setOrderNum,
    setDateRange,
    dateRange,
  };
};
