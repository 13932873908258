import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { AppDispatch, getMultiLogin } from 'store';
import { setShowModalInvoice, showLoader } from 'store/ui.slice';
import {
  getCreatedOrders,
  getOrderDocsUrl,
  getPaymentInvoiceLink,
} from 'urls';
import { PICKUP_ADRESS, currency } from 'utils';

import DownloadLink from 'components/download-link/download-link';
import { ModalPayOrder } from 'components/modal-pay-order/modal-pay-order';
import { PayItems } from 'components/pay-items/pay-items';

import { useOrderDocuments } from 'shared/hooks/use-order-documents.hook';
import { usePartner } from 'shared/hooks/use-partner.hook';
import { usePayment } from 'shared/hooks/use-payment';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import copy from 'images/icons/copy.svg';
import info from 'images/icons/info.svg';
import load from 'images/icons/load.svg';
import payCard from 'images/icons/payCard.svg';
import refresh from 'images/icons/refresh.svg';
import sign from 'images/icons/sign.svg';
import link from 'images/icons/transition.svg';

import { DeliveryStatusIcon } from './delivery-status-icon';
import { DocumentStatusIcon } from './document-status-icon';
import styles from './history-orders-subitem.module.css';
import { PayStatusIcon } from './pay-status-icon';
import { PickupStatusIcon } from './pickup-status-icon';

const patternForFullTelephone =
  /(\+7|8|7)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;

const copyToClipboard = (str: string, id: string) => {
  if (
    navigator &&
    navigator.clipboard &&
    navigator.clipboard.writeText
  ) {
    const tooltip = document.getElementById(id);
    if (tooltip) {
      tooltip.innerHTML = 'Скопировано!';
    }

    return navigator.clipboard.writeText(str);
  }
  return Promise.reject(
    new Error('The Clipboard API is not available.'),
  );
};

const showTooltip = (str: string, id: string) => {
  const tooltip = document.getElementById(id);
  if (tooltip) {
    tooltip.innerHTML = str;
  }
};

interface IHistoryOrdersSubItemProps {
  orderId: number;
  contactName: string;
  contactPhone: string;
  deliveryAddress: string;
  trackNumber: string;
  storageId: string;
  deliveryStatus: string;
  deliveryStatusName: string;
  statusName: string;
  payStatusName: string;
  payRemain: number;
  price: number;
  adressDesc: string;
  documentStatusName: string;
  deliveryPrice: number;
  vendorName: string;
}

const REFRESH_DELAY = 3000;

const HistoryOrdersSubItem: FC<IHistoryOrdersSubItemProps> = memo(
  ({
    orderId,
    contactName,
    contactPhone,
    deliveryAddress,
    trackNumber,
    storageId,
    deliveryStatus,
    deliveryStatusName,
    statusName,
    payStatusName,
    payRemain,
    price,
    adressDesc,
    documentStatusName,
    deliveryPrice,
    vendorName,
  }) => {
    const progressPercent = Math.trunc((payRemain * 100) / price);

    const [loading, setLoading] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [lastFetchTime, setLastFetchTime] = useState(0);
    const [fetchOrderNumber, setFetchOrderNumber] = useState<
      number | null
    >(null);

    const { type } = useSelector(getMultiLogin);
    const orgId = multiLoginStorage.getOrgId();
    const payment = usePayment();
    const orderDocuments = useOrderDocuments();
    const isPartner = usePartner();
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch<AppDispatch>();

    const handleActiveModal = useCallback(() => {
      setModalActive(true);
    }, []);

    const fetchOrder = useCallback(async () => {
      const { data } = await httpClient.get(
        getOrderDocsUrl(orgId, orderId),
      );
      const { orderNumber } = data[0];

      if (orderNumber) {
        setFetchOrderNumber(orderNumber);
      }
    }, [orderId, orgId]);

    const handleRefresh = useCallback(() => {
      const now = Date.now();
      if (now - lastFetchTime > REFRESH_DELAY) {
        fetchOrder();
        setLastFetchTime(now);
      }
    }, [lastFetchTime, fetchOrder]);

    useEffect(() => {
      if (fetchOrderNumber) return;

      const intervalId = setInterval(() => {
        fetchOrder();
      }, REFRESH_DELAY);

      return () => clearInterval(intervalId);
    }, [fetchOrder, fetchOrderNumber]);

    const isNotPaidAndLK = useMemo(() => {
      return payStatusName === 'Не оплачен' && type === 'LK';
    }, [payStatusName, type]);

    useEffect(
      () => {
        (async () => {
          dispatch(showLoader(true));
          setLoading(true);
          await orderDocuments.getOrderData({ orgId, orderId });
          await orderDocuments.getDocs({ orgId, orderId });
          dispatch(showLoader(false));
          setLoading(false);
        })();
      },
      /* eslint-disable-next-line */
      [
        dispatch,
        orgId,
        orderId,
        orderDocuments.getOrderData,
        orderDocuments.getDocs,
      ],
    );

    /** Удаление get параметра после создание заказа партнёром */
    useEffect(() => {
      if (searchParams.get('payment') === 'true' && isPartner) {
        dispatch(setShowModalInvoice(false));
        searchParams.delete('payment');
        setSearchParams(searchParams);
      }
    }, [dispatch, searchParams, setSearchParams, isPartner]);

    return (
      <div className={styles['detailed-info']}>
        {/** Данные о товаре */}
        <div className={`${styles.block} text-start text-md-end`}>
          <div
            className={`${styles['col-name']} d-none d-md-block mb-2`}
          >
            <Row>
              <Col md={6} xs={12}>
                <div className="text-start">Наименование</div>
              </Col>

              <Col md={2} xs={12}>
                <div>Кол-во</div>
              </Col>

              <Col md={2} xs={12}>
                <div>Цена за шт.</div>
              </Col>

              <Col md={2} xs={12}>
                <div>Сумма</div>
              </Col>
            </Row>
          </div>

          <ol className={styles.list}>
            {orderDocuments.orderInfo.map((item, i) => (
              <li key={item.productName + String(i)} className="mb-4">
                <div className={styles['col-desc']}>
                  <Row className="text-center text-md-end">
                    <Col md={6} xs={12}>
                      <div
                        className={`${styles.desc} ${styles['text-left']}`}
                      >
                        {item.productName}
                      </div>
                    </Col>

                    <Col
                      md={2}
                      xs={3}
                      className="pt-3 pt-md-0 pl-0 pr-0"
                    >
                      <div>{item.quantity}</div>
                    </Col>

                    <Col
                      md={1}
                      xs={1}
                      className="pt-3 pt-md-0 pl-0 pr-0"
                    >
                      <div
                        className={`${styles['text-center']} ${styles.prefix}`}
                      >
                        x
                      </div>
                    </Col>

                    <Col
                      md={1}
                      xs={3}
                      className="pt-3 pt-md-0 pl-0 pr-0"
                    >
                      <div>{item.price} ₽</div>
                    </Col>

                    <Col
                      md={1}
                      xs={1}
                      className="pt-3 pt-md-0 d-block d-md-none pl-0 pr-0"
                    >
                      <div
                        className={`${styles['text-center']} ${styles.prefix}`}
                      >
                        =
                      </div>
                    </Col>

                    <Col
                      md={2}
                      xs={4}
                      className="pt-3 pt-md-0 pl-5 pr-0"
                    >
                      <div>{item.sum} ₽</div>
                    </Col>
                  </Row>
                </div>
              </li>
            ))}
          </ol>
        </div>

        {/** Данные о доставке */}
        <div className={styles.block}>
          {deliveryStatus === 'Доставка' && (
            <div
              className={`${styles['col-desc']} ${styles['text-left']}`}
            >
              <Row className="mb-4">
                <Row>
                  <Col
                    xs={12}
                    md={4}
                    xl={3}
                    className={styles['media-width']}
                  >
                    <div className="d-flex">
                      <DeliveryStatusIcon
                        deliveryStatusName={deliveryStatusName}
                      />
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    md={5}
                    xl={3}
                    className={`${styles['media-width']} pt-3 pt-md-0`}
                  >
                    <a
                      data-testid="his-order-subitem-link-1"
                      className={styles.link}
                      href="https://my.versta24.ru/trackOrder"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Отслеживание на сайте ТК
                      <img className={styles.img} src={link} alt="" />
                    </a>
                  </Col>
                </Row>

                <div className={`${styles['flex-wrap']} d-flex mt-3`}>
                  <Col
                    xs={12}
                    md={10}
                    xl={4}
                    className={`${styles['media-width']} pt-3 pt-md-0`}
                  >
                    <div
                      className={`${styles['with-icon']} ${styles['order-num']} ${styles['with-icon-media']}`}
                    >
                      Номер заказа в транспортной компании
                      <div className={styles['tooltip-copy']}>
                        <p
                          className={styles['tooltiptext-copy']}
                          id="tooltipText"
                        >
                          Отслеживайте путь своего заказа по &quot;
                          номеру заказа в ТК &quot; на сайте:{' '}
                          <a
                            data-testid="his-order-subitem-link-2"
                            href="https://my.versta24.ru/trackOrder"
                          >
                            https://my.versta24.ru/trackOrder
                          </a>
                        </p>
                        <img
                          data-testid="his-order-mouse-event-1"
                          src={info}
                          onMouseOut={() => {
                            showTooltip(
                              "Отслеживайте путь своего заказа по 'номеру заказа в ТК' на сайте: <a data-testid='his-order-subitem-link-3' href='https://my.versta24.ru/trackOrder'>https://my.versta24.ru/trackOrder</a>",
                              'tooltipText',
                            );
                          }}
                          alt=""
                          onBlur={() => false}
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    md={2}
                    xl={2}
                    className={`${styles.centered} pt-3 pt-md-0`}
                  >
                    {trackNumber && (
                      <div
                        className={`${styles['with-icon']} ${styles['track-num']}`}
                      >
                        {trackNumber}
                        <div className={styles['tooltip-copy']}>
                          <span
                            className={styles['tooltiptext-copy']}
                            id="tooltipCopy"
                          >
                            Копировать
                          </span>
                          <img
                            data-testid="his-order-event-1"
                            src={copy}
                            onClick={() => {
                              copyToClipboard(
                                trackNumber,
                                'tooltipCopy',
                              );
                            }}
                            onMouseOut={() => {
                              showTooltip(
                                'Копировать',
                                'tooltipCopy',
                              );
                            }}
                            alt=""
                            onBlur={() => false}
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                </div>
              </Row>
            </div>
          )}

          {deliveryStatus === 'Самовывоз' && (
            <div
              className={`${styles['col-desc']} ${styles['text-left']}`}
            >
              <Row className="mb-4">
                <div className={`${styles['flex-wrap']} d-flex`}>
                  <Col xs={12} md={4} xl={3}>
                    <div className="d-flex">
                      <PickupStatusIcon
                        deliveryStatusName={deliveryStatusName}
                      />
                    </div>
                  </Col>

                  <Col xs={12} md={5} xl={3} className="pt-3 pt-md-0">
                    <Link
                      to="/information#pickup"
                      data-testid="his-order-subitem-link-4"
                      className={styles.link}
                    >
                      Подробнее о самовывозе
                      <img className={styles.img} src={link} alt="" />
                    </Link>
                  </Col>
                </div>

                <div className={`${styles['flex-wrap']} d-flex mt-3`}>
                  <Col
                    xs={12}
                    md={10}
                    xl={4}
                    className={`${styles['media-width']} pt-3 pt-md-0`}
                  >
                    <div
                      className={`${styles['with-icon']} ${styles['order-num']} ${styles['with-icon-media']}`}
                    >
                      Номер заказа на складе
                      <div className={styles['tooltip-copy']}>
                        <p
                          className={styles['tooltiptext-copy']}
                          style={{ width: '14rem' }}
                          id="tooltipText"
                        >
                          Сообщите номер заказа на складе сотруднику
                          склада при получении заказа. Если забор
                          заказа со склада осуществляет курьер или
                          транспортная компания, то обязательно
                          сообщите им номер заказа для получения.
                        </p>
                        <img
                          data-testid="his-order-event-2"
                          src={info}
                          onMouseOut={() => {
                            showTooltip(
                              `Cообщите номер заказа на складе сотруднику склада при получении заказа.
                            Если забор заказа со склада осуществляет курьер или транспортная компания,
                            то обязательно сообщите им номер заказа для получения.`,
                              'tooltipText',
                            );
                          }}
                          alt=""
                          onBlur={() => false}
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    md={2}
                    xl={2}
                    className={`${styles.centered} pt-3 pt-md-0`}
                  >
                    <div
                      className={`${styles['with-icon']} ${styles['track-num']}`}
                    >
                      {storageId}
                      <div className={styles['tooltip-copy']}>
                        <span
                          className={styles['tooltiptext-copy']}
                          id="tooltipCopy"
                        >
                          Копировать
                        </span>
                        <img
                          data-testid="his-order-event-3"
                          src={copy}
                          onClick={() => {
                            copyToClipboard(storageId, 'tooltipCopy');
                          }}
                          onMouseOut={() => {
                            showTooltip('Копировать', 'tooltipCopy');
                          }}
                          onBlur={() => false}
                          alt=""
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          )}

          <div
            className={
              deliveryStatus === 'Доставка'
                ? 'd-flex align-items-center justify-content-between'
                : ''
            }
          >
            <ul
              className={`${styles['profile-list']} d-sm-flex justify-content-between flex-wrap`}
            >
              <li className={`${styles['profile-item']} mb-3`}>
                <div className={styles['profile-title']}>
                  Контактное лицо
                </div>
                <div className={styles['profile-desc']}>
                  {contactName}
                </div>
              </li>

              <li className={`${styles['profile-item']} mb-3`}>
                <div className={styles['profile-title']}>
                  Контактный телефон
                </div>
                <div className={styles['profile-desc']}>
                  {contactPhone
                    .replace(/[^0-9]/g, '')
                    .replace(
                      patternForFullTelephone,
                      '+7 ($2) $3-$4-$5',
                    )}
                </div>
              </li>

              <li className={`${styles['profile-item']} mb-3`}>
                <div className={styles['profile-title']}>
                  {' '}
                  {deliveryStatus === 'Самовывоз'
                    ? 'Адрес самовывоза'
                    : 'Адрес доставки'}
                </div>

                <div className={styles['profile-desc']}>
                  {deliveryStatus === 'Самовывоз'
                    ? PICKUP_ADRESS
                    : deliveryAddress}
                </div>
              </li>

              {adressDesc !== null ? (
                <li className={`${styles['profile-item']} mb-3`}>
                  <div className={styles['profile-title']}>
                    Комментарии к заказу
                  </div>
                  <div className={styles['profile-desc']}>
                    {adressDesc}
                  </div>
                </li>
              ) : null}

              {deliveryStatus === 'Доставка' && (
                <li className={`${styles['profile-item']} mb-3`}>
                  <div className={styles['profile-title']}>
                    Тариф доставки
                  </div>
                  <div className={styles['profile-desc']}>
                    {vendorName}
                  </div>
                </li>
              )}
            </ul>

            {deliveryStatus === 'Доставка' && (
              <div
                className={`${styles['delivery-price']} text-nowrap`}
              >
                {deliveryPrice} ₽
              </div>
            )}
          </div>
        </div>

        {/** УПД */}
        {orderDocuments.updFileInfo && (
          <div className={styles.block}>
            <Row>
              <Col md={4} xl={3} xs={12}>
                {!loading && (
                  <DocumentStatusIcon
                    documentStatusName={documentStatusName}
                  />
                )}
              </Col>

              <Col md={3} xl={2} xs={12} className="pt-3 pt-md-0">
                <div
                  className={`${styles.load} d-flex align-items-center`}
                >
                  {!loading && orderDocuments.updFileInfo?.docId && (
                    <DownloadLink
                      link={`${getCreatedOrders(
                        orgId,
                      )}/${orderId}/doc/${
                        orderDocuments.updFileInfo?.docId
                      }/attachment`}
                      payload={{ responseType: 'blob' }}
                      textLink="Скачать УПД"
                      linkStyle="short"
                      fileName={
                        orderDocuments.updFileInfo?.docFile.docName
                      }
                      documentType="application/pdf"
                      iconSrc={''}
                      showedDownloadIcon={null}
                      isDisabled={!orderDocuments.updFileInfo?.docId}
                    />
                  )}
                </div>
              </Col>

              <Col md={5} xl={7} xs={12} className="pt-3 pt-md-0">
                <a
                  data-testid="his-order-subitem-link-6"
                  href="https://edo.platformaofd.ru/"
                  target="_blank"
                  className={`${styles.button} float-md-end justify-content-center`}
                  rel="noreferrer"
                >
                  <img src={sign} alt="" />
                  Подписать в «Платформа ЭДО»
                </a>
              </Col>
            </Row>
          </div>
        )}

        {/** Оплата */}
        <div className="mt-3">
          <Row className={styles['pay-status-container']}>
            <Col md={4} lg={4} xs={12} xl={2}>
              <PayStatusIcon
                statusName={statusName}
                payStatusName={payStatusName}
                percent={progressPercent}
              />
            </Col>

            <Col
              md={3}
              lg={3}
              xs={12}
              xl={4}
              className="pt-3 pt-md-0"
            >
              <div className={styles.load}>
                {!loading && fetchOrderNumber && (
                  <DownloadLink
                    link={getPaymentInvoiceLink(orderId)}
                    payload={{ responseType: 'blob' }}
                    textLink="Скачать счет на оплату"
                    linkStyle="short"
                    fileName={
                      orderDocuments.invoiceFileInfo?.docFile.docName
                    }
                    documentType="application/pdf"
                    iconSrc={''}
                    showedDownloadIcon={null}
                    isDisabled={!fetchOrderNumber}
                  />
                )}

                {!fetchOrderNumber &&
                  (payStatusName === 'Не оплачен' ? (
                    <>
                      <div className={styles['check-wrap']}>
                        <img
                          src={load}
                          className={styles['animate-spin']}
                          alt="load"
                        />
                        Счет формируется
                      </div>

                      {/*TODO: Временное скрытие оплаты картой. Вернуть когда будет готов payment service*/}
                      {type === 'LK' && (
                        <button
                          className={styles['refresh-btn']}
                          onClick={handleActiveModal}
                        >
                          Оплатить
                        </button>
                      )}

                      <button
                        className={styles['refresh-btn']}
                        onClick={handleRefresh}
                      >
                        <img src={refresh} alt="refresh" />
                        Обновить
                      </button>
                    </>
                  ) : (
                    //TODO: Временное скрытие оплаты картой. Вернуть когда будет готов payment service
                    isNotPaidAndLK && (
                      <button
                        className={styles['pay-btn']}
                        onClick={handleActiveModal}
                      >
                        <img src={payCard} alt="pay-btn" />
                        Оплатить заказ
                      </button>
                    )
                  ))}
                {payment.error && (
                  <p className={styles.error}>{payment.error}</p>
                )}
              </div>

              {isNotPaidAndLK && (
                <ModalPayOrder
                  modalActive={modalActive}
                  setModalActive={setModalActive}
                >
                  <PayItems
                    orderNumber={fetchOrderNumber}
                    email={payment.email}
                    setEmail={payment.setEmail}
                    error={payment.error}
                    handlePayClick={() =>
                      payment.handlePayClick(orderId)
                    }
                    invoiceFileInfo={orderDocuments.invoiceFileInfo}
                  />
                </ModalPayOrder>
              )}
            </Col>

            <Col
              md={12}
              lg={5}
              xs={12}
              xl={6}
              className="pt-3 pt-md-0"
            >
              {statusName === 'Оплачен' ? (
                <div
                  className={`${styles['total-sum']} ${styles['pull-right']}`}
                >
                  <p className={styles.text}>Сумма заказа</p>
                  <p className={styles.price}>{currency(price)}₽</p>
                </div>
              ) : (
                <div className="float-sm-end d-flex align-items-end">
                  <div className={styles['text-gray']}>
                    Осталось оплатить:
                  </div>
                  <div className={styles['remain-sum']}>
                    {currency(payRemain)} ₽
                  </div>
                  <div>из {currency(price)} ₽</div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  },
);

HistoryOrdersSubItem.displayName = 'HistoryOrdersSubItem';

export default HistoryOrdersSubItem;
