import { FC, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, getIsOffer, getMultiLogin } from 'store';
import {
  setContinueNoEdoRegistered,
  setEdoFlowSuccess,
} from 'store/edo.slice';
import { setOrderLoaderFlag, setStartOrder } from 'store/order.slice';
import { showModal } from 'store/ui.slice';

import CustomBtn from 'components/custom-btn/custom-btn';

import { USER_TYPES } from 'shared/const';

import checkEdoImg from 'images/accept-edo.svg';

import { useCaptcha } from '../registration/hooks';
import styles from './edo-registration.module.css';

const EdoRegistration: FC = memo(() => {
  const { type } = useSelector(getMultiLogin);
  const { isEnableTest } = useCaptcha();
  const { isOfferAccepted } = useSelector(getIsOffer);

  const dispatch: AppDispatch = useDispatch();

  const isLkUser = useMemo(() => type === USER_TYPES.LK, [type]);

  const goToRegistration = useCallback(() => {
    const host = isEnableTest ? '-test' : '';

    const url = isLkUser
      ? `https://lk${host}.platformaofd.ru/web/auth/documents/edo`
      : `https://pk${host}.platformaofd.ru/edo/about`;
    window.open(url, '_blank');
  }, [isLkUser, isEnableTest]);

  const closeModal = useCallback(() => {
    dispatch(setEdoFlowSuccess(false));
    dispatch(setContinueNoEdoRegistered(true));

    if (isOfferAccepted) {
      dispatch(setStartOrder(true));
      dispatch(setOrderLoaderFlag(true));
      dispatch(showModal(false));
    }
  }, [dispatch, isOfferAccepted]);

  return (
    <>
      <p>
        <img className="mx-auto" src={checkEdoImg} alt="" />
      </p>

      <p className={`${styles.text} mt-3`}>
        Обмен документами с «Торговый дом Платформа» происходит в ЭДО.
        Пройдите регистрацию в сервисе «Платформа ЭДО»
      </p>

      <p className={styles.text}>
        Если Вы уже работаете с оператором ЭДО, то отправьте
        приглашение ООО «Торговый дом Платформа» по ID =
        2VO-9704104605-7704010010000000000000000000000 для настройки
        роуминга.
      </p>

      <div className="mt-auto">
        <CustomBtn
          theme="edo"
          type="button"
          text="Перейти к регистрации"
          disabled={false}
          onClick={goToRegistration}
          testId="edo-reg-1"
          loading={false}
          isLink
        />

        {isLkUser && (
          <CustomBtn
            theme="lkfn"
            type="button"
            text="Продолжить без регистрации"
            disabled={false}
            onClick={closeModal}
            testId="edo-reg-2"
            loading={false}
            customCls="mt-2"
            isLink={false}
          />
        )}
      </div>
    </>
  );
});

EdoRegistration.displayName = 'EdoRegistration';

export default EdoRegistration;
