import { useCallback, useMemo, useState } from 'react';

import { HOSTS } from 'shared/const';

export const STATUSES = {
  hidden: {
    type: 'hidden',
    message: '',
  },
  visible: {
    type: 'visible',
    message: '',
  },
  networkError: {
    type: 'networkError',
    message: 'Ошибка сети',
  },
  success: {
    type: 'success',
    message: '',
  },
  tokenExpired: {
    type: 'tokenExpired',
    message: 'Срок действия токена истек',
  },
};

const TEST_HOSTS = [
  HOSTS.lk.dev.inner,
  HOSTS.lk.dev.external,
  HOSTS.local.dev.inner,
];

type TStatus = {
  type: string;
  message: string;
};

interface UseCaptchaState {
  token: string;
  status: TStatus;
}

export const useCaptcha = () => {
  const [state, setState] = useState<UseCaptchaState>({
    token: '',
    status: STATUSES.hidden,
  });

  const isEnableTest = useMemo(() => {
    return TEST_HOSTS.some((testHost) =>
      window.location.host.includes(testHost),
    );
  }, []);

  const isVisible = useMemo(() => {
    return [STATUSES.visible, STATUSES.success].includes(
      state.status,
    );
  }, [state.status]);

  const isLoading = useMemo(() => {
    if (!isVisible) return false;
    return !!state.token;
  }, [isVisible, state.token]);

  const handleChallengeVisible = useCallback(
    () => setState((prev) => ({ ...prev, status: STATUSES.visible })),
    [],
  );

  const handleChallengeHidden = useCallback(
    () => setState((prev) => ({ ...prev, status: STATUSES.hidden })),
    [],
  );

  const handleNetworkError = useCallback(
    () =>
      setState((prev) => ({
        ...prev,
        status: STATUSES.networkError,
      })),
    [],
  );

  const handleSuccess = useCallback((newToken: string) => {
    setState((prev) => ({
      ...prev,
      token: newToken,
      status: STATUSES.success,
    }));
  }, []);

  const handleTokenExpired = useCallback(() => {
    setState((prev) => ({
      ...prev,
      token: '',
      status: STATUSES.tokenExpired,
    }));
  }, []);

  return {
    ...state,
    isVisible,
    isLoading,
    isEnableTest,
    handleChallengeVisible,
    handleChallengeHidden,
    handleNetworkError,
    handleSuccess,
    handleTokenExpired,
  };
};
