import { FC, memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getModals } from 'store';

import styles from './overlay.module.css';

interface IOverlayProps {
  onClose: (() => void) | null;
}

const Overlay: FC<IOverlayProps> = memo(({ onClose }) => {
  const { isModalActive } = useSelector(getModals);

  const handleClose = useCallback(
    (e: { keyCode: number }) => {
      if (e.keyCode === 27 && isModalActive && onClose) {
        onClose();
      }
    },
    [isModalActive, onClose],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleClose);
    return () => window.removeEventListener('keydown', handleClose);
  });

  return (
    <div
      onClick={() => onClose && onClose()}
      role="button"
      aria-hidden="true"
      className={styles.overlay}
    />
  );
});

Overlay.displayName = 'Overlay';

export default Overlay;
