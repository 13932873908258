import { FC, ReactNode, memo, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AppDispatch } from 'store';
import { getOrganization } from 'store/auth.slice';

import { useAuth } from 'shared/hooks/use-auth';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';
import { tokens } from 'shared/utils/tokens.util';

type TLayoutProps = { children: ReactNode };

export const Layout: FC<TLayoutProps> = memo((props) => {
  const { children } = props;

  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const orgIdParam = searchParams.get('orgId');
  const isAuth = !!tokens.getAccess() && !!tokens.getRefresh();

  const isLastPage = useMemo(
    () => {
      const currentPathname = window.location.pathname;
      const lastPathname = localStorage.getItem(
        'lastUpdatedLoginPage',
      );
      return currentPathname === lastPathname;
    },
    /* eslint-disable-next-line */
    [location],
  );

  const isMultiLoginUrl = useMemo(
    () => {
      return window.location.pathname === '/multi-login';
    },
    /* eslint-disable-next-line */
    [location],
  );

  /** Получение данных о мультилогине */
  useEffect(() => {
    if (window.location.pathname === '/error500') return;
    if (auth?.isDataLoading) return;

    if (orgIdParam && isAuth) {
      multiLoginStorage.setOrgId(orgIdParam);
    }
    dispatch(getOrganization());
  }, [auth?.isDataLoading, dispatch, orgIdParam, isAuth]);

  /** Инициализация SSO  */
  useEffect(
    () => {
      auth?.init();
    },
    /* eslint-disable-next-line */
    [auth?.init],
  );

  useEffect(
    () => {
      if (!auth?.isAuthenticated) return;
      if (isMultiLoginUrl) return;
      if (isLastPage) return;
      auth?.logIn({ prompt: 'none' });
    },
    /* eslint-disable-next-line */
    [isLastPage, isMultiLoginUrl, auth?.isAuthenticated],
  );

  if (auth?.isAuthenticated && !isLastPage && !isMultiLoginUrl)
    return null;

  return <>{children}</>;
});

Layout.displayName = 'Layout';
