import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getMultiLogin, getOrder } from 'store';

import ContentLoader from 'components/content-loader/content-loader';
import CustomBtn from 'components/custom-btn/custom-btn';
import CustomInput from 'components/custom-input/custom-input';
import Datepicker from 'components/datepicker/datepicker';
import HistoryOrderList from 'components/history-orders-list/history-orders-list';
import Loader from 'components/loader/loader';
import { ModalPayOrder } from 'components/modal-pay-order/modal-pay-order';
import OrderSelect from 'components/order-select/order-select';
import { PayItems } from 'components/pay-items/pay-items';
import { StatusPayOrder } from 'components/status-pay-order/status-pay-order';

import { PAGINATION_STEP } from 'shared/const/orders-pagination.const';
import { useOrdersPagination } from 'shared/hooks/use-orders-pagination.hook';
import { useOrders } from 'shared/hooks/use-orders.hook';
import { usePartner } from 'shared/hooks/use-partner.hook';
import { usePayment } from 'shared/hooks/use-payment';
import { tokens } from 'shared/utils/tokens.util';

import checkGreen from 'images/icons/check-green.svg';
import clock from 'images/icons/clock.svg';
import redCross from 'images/icons/red-cross.svg';
import refresh from 'images/icons/refresh.svg';

import styles from './orders.module.css';

export type TData = Date | null | undefined;

const deliveryTypeOptions = [
  { value: 0, text: 'Самовывоз' },
  { value: 1, text: 'Доставка' },
];

export const Orders: FC = memo(() => {
  const [deliveryType, setDeliveryType] = useState<string | null>('');

  const token = tokens.getAccess();

  const multiLogin = useSelector(getMultiLogin, (prev, next) => {
    return prev.userId === next.userId;
  });
  const { ordersHistory } = useSelector(getOrder, (prev, next) => {
    return prev.ordersHistory.length === next.ordersHistory.length;
  });

  const isPartner = usePartner();
  const payment = usePayment();
  const ordersPagination = useOrdersPagination();
  const ordersInfo = useOrders({ ...ordersPagination }, deliveryType);

  const handleDatepickerReset = () => ordersInfo.setDateRange([]);

  const handleDeliveryTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setDeliveryType(e.target.value);

  const handleDeliveryTypeReset = () => setDeliveryType(null);

  const handleButtonLoading = useCallback(() => {
    ordersPagination.setNextPaginationStep((prev: number) => {
      return prev + PAGINATION_STEP;
    });
    const url = ordersPagination.createPaginationUrl(
      ordersPagination.nextPaginationStep + PAGINATION_STEP,
    );
    ordersInfo.fetchOrdersInfo(url);
  }, [ordersInfo, ordersPagination]);

  const getOrderById = useCallback(
    (orderId: number) => {
      return ordersHistory?.find(
        (order) => order.orderId === orderId,
      );
    },
    [ordersHistory],
  );

  const getDeliveryTypeByOrderId = useCallback(
    (orderId: number) => {
      const deliveryType = getOrderById(orderId)?.deliveryTypeId;

      let result = null;

      if (deliveryType === 0) result = 'Самовывоз';
      if (deliveryType === 1) result = 'Доставка';

      return result;
    },
    [getOrderById],
  );

  const getDeliveryAddressByOrderId = useCallback(
    (orderId: number) => {
      return getOrderById(orderId)?.orderDeliveryAddress || '';
    },
    [getOrderById],
  );

  useEffect(
    () => {
      if (!multiLogin.userId || !!ordersHistory.length) return;
      ordersInfo.fetchOrdersInfo();
    },
    /* eslint-disable-next-line */
    [multiLogin.userId, ordersHistory.length],
  );

  useEffect(
    () => {
      if (!payment.isStatusModalVisible || !payment.urlOrderID) {
        return;
      }
      payment.fetchStatus();
    },
    /* eslint-disable-next-line */
    [payment.isStatusModalVisible, payment.urlOrderID],
  );

  if (!token) {
    return (
      <div className={styles['no-auth']}>
        Для просмотра истории заказов пройдите авторизацию
      </div>
    );
  }

  return (
    <div className={styles.orders}>
      <div className={styles.head}>
        {/** Заголовок */}
        <h3 className={styles.title}>История заказов</h3>

        {/** Фильтр */}
        <form action="" className={styles.form}>
          <div className={styles['form-desc']}>
            <Row className="align-items-center">
              <Col md={4} xs={12} className="flex-fill">
                <CustomInput
                  testId="orders-input-1"
                  onChange={(e) =>
                    ordersInfo.setOrderNum(e.target.value)
                  }
                  label="Номер заказа"
                  type="text"
                  theme="edo"
                  placeholder="Введите номер"
                />
              </Col>

              <Col md={4} xs={12} className="flex-fill mt-3 mt-md-0">
                <Datepicker
                  dateRange={ordersInfo.dateRange}
                  func={ordersInfo.setDateRange}
                  onDatepickerReset={handleDatepickerReset}
                />
              </Col>

              {isPartner && (
                <Col md={4} xs={12} className="flex-fill">
                  <div className={styles['select-wrapper']}>
                    <OrderSelect
                      testId="orders-select-1"
                      onChange={handleDeliveryTypeChange}
                      placeholder="Способ получения"
                      options={deliveryTypeOptions}
                      onReset={handleDeliveryTypeReset}
                      disabled={!deliveryTypeOptions.length}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </form>
      </div>

      <div className={styles.main}>
        <div className={styles.container}>
          <div className="list">
            {/** Заголовок таблицы истории заказов */}
            <Row className={`${styles['col-name']} d-none d-lg-flex`}>
              <Col xs={1}>
                <div className="num">№ заказа</div>
              </Col>

              <Col xs={2}>
                <div className={styles.date}>Дата заказа</div>
              </Col>

              <Col xs={3}>
                <div className={styles.author}>Автор</div>
              </Col>

              <Col xs={1}>
                <div className={styles.delivery}>Тип доставки</div>
              </Col>

              <Col xs={1}>
                <div className="ms-3 d-flex">Сумма</div>
              </Col>

              <Col xs={4}>
                <div className={styles.sum}>Сумма заказа</div>
              </Col>
            </Row>

            {ordersInfo.isOrdersLoading && (
              <ContentLoader placeholderType="history-order" />
            )}

            {!ordersInfo.isOrdersLoading && tokens.getAccess() && (
              <>
                {!!ordersHistory.length && (
                  <HistoryOrderList
                    filteredOrders={ordersInfo.filteredOrders}
                  />
                )}

                {!ordersPagination.isLoadMoreDisabled && (
                  <div className={styles['btn-wrapper']}>
                    <CustomBtn
                      theme="edo"
                      type="button"
                      text="Загрузить ещё"
                      disabled={false}
                      onClick={handleButtonLoading}
                      testId="orders-btn-1"
                      loading={false}
                      isLink={false}
                      customCls={null}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/** Модальное окно с отображением статуса платежа */}
      {payment.urlOrderID && (
        <>
          {payment.isStatusLoading && <Loader />}

          {!payment.isStatusLoading && (
            <ModalPayOrder
              modalActive={payment.isStatusModalVisible}
              setModalActive={payment.setIsStatusModalVisible}
            >
              <>
                {payment.status === 'SUCCEEDED' && (
                  <>
                    {/** Доставка */}
                    {getDeliveryTypeByOrderId(
                      Number(payment.urlOrderID),
                    ) !== 'Доставка' && (
                      <div className={styles['container-pay-order']}>
                        <StatusPayOrder
                          header="Оплата прошла успешно"
                          iconStatus={checkGreen}
                          type="successfulDelivery"
                          deliveryNumber={Number(payment.urlOrderID)}
                          orderDate={
                            getOrderById(Number(payment.urlOrderID))
                              ?.orderDate
                          }
                          address={getDeliveryAddressByOrderId(
                            Number(payment.urlOrderID),
                          )}
                        />

                        <button
                          className={styles['return-list-order']}
                          onClick={() =>
                            payment.setIsStatusModalVisible(false)
                          }
                        >
                          Перейти к списку заказов
                        </button>
                      </div>
                    )}

                    {/** Самовывоз */}
                    {getDeliveryTypeByOrderId(
                      Number(payment.urlOrderID),
                    ) !== 'Самовывоз' && (
                      <div className={styles['container-pay-order']}>
                        <StatusPayOrder
                          header="Оплата прошла успешно"
                          iconStatus={checkGreen}
                          type="successfulPickup"
                          deliveryNumber={Number(payment.urlOrderID)}
                          orderDate={
                            getOrderById(Number(payment.urlOrderID))
                              ?.orderDate
                          }
                          address={getDeliveryAddressByOrderId(
                            Number(payment.urlOrderID),
                          )}
                          // TODO Пока ждем новой формулировки, без указания pickupNumber
                          // pickupNumber="???"
                        />

                        <button
                          className={styles['return-list-order']}
                          onClick={() =>
                            payment.setIsStatusModalVisible(false)
                          }
                        >
                          Перейти к списку заказов
                        </button>
                      </div>
                    )}
                  </>
                )}

                {payment.status === 'CANCELED' && (
                  <div className={styles['container-reject-order']}>
                    <StatusPayOrder
                      header="Заказ не был оплачен"
                      iconStatus={redCross}
                      customStyle={styles['reject-status']}
                      type="rejected"
                      deliveryNumber={Number(payment.urlOrderID)}
                    />

                    <PayItems
                      orderNumber={Number(payment.urlOrderID)}
                      email={payment.email}
                      setEmail={payment.setEmail}
                      error={payment.error}
                      handlePayClick={() =>
                        payment.handlePayClick(
                          Number(payment.urlOrderID),
                        )
                      }
                    />
                  </div>
                )}

                {payment.status === 'NEW' && (
                  <div className={styles['container-pay-order']}>
                    <StatusPayOrder
                      header="Платеж в обработке"
                      iconStatus={clock}
                      customStyle={styles['pending-status']}
                      type="pending"
                      deliveryNumber={Number(payment.urlOrderID)}
                    />

                    <div className={styles['btn-container-pend']}>
                      <button
                        className={styles['refresh-btn']}
                        onClick={payment.fetchStatus}
                      >
                        <img src={refresh} alt="refresh" />
                        Обновить
                      </button>

                      <button
                        className={styles['return-list-order']}
                        onClick={() =>
                          payment.setIsStatusModalVisible(false)
                        }
                      >
                        Перейти к списку заказов
                      </button>
                    </div>
                  </div>
                )}
              </>
            </ModalPayOrder>
          )}
        </>
      )}
    </div>
  );
});

Orders.displayName = 'Orders';
