import classNames from 'classnames/bind';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getDeliveryState,
  getModals,
  getOrder,
  getProductsList,
} from 'store';

import Aside from 'components/aside/aside';
import ContentLoader from 'components/content-loader/content-loader';
import ProductsCard from 'components/products-card/products-card';

import { PRODUCTS_STATUSES } from 'shared/const';

import arrowUp from 'images/icons/arrow-up.svg';

import styles from './products.module.css';

const cx = classNames.bind(styles);

const deliveryOptions = [
  { value: 0, text: 'Самовывоз' },
  { value: 1, text: 'Доставка' },
];

export const ProductsPage: FC = memo(() => {
  const [scrollVisible, setScrollVisible] = useState(false);

  const products = useSelector(getProductsList);
  const order = useSelector(getOrder);
  const { deliveryData } = useSelector(getDeliveryState);
  const { showModalInvoice } = useSelector(getModals);
  const { pathname } = useLocation();

  const getSelectedDeliveryOption = useCallback(
    () =>
      deliveryOptions.filter(
        (item) => item.text === deliveryData.deliveryType,
      )[0].value,
    [deliveryData.deliveryType],
  );

  useEffect(() => {
    getSelectedDeliveryOption();
  }, [getSelectedDeliveryOption, deliveryData.deliveryType]);

  const handleScrollUp = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const scrollingStart = useCallback(() => {
    setScrollVisible(false);
  }, []);

  const scrollingEnd = useCallback(() => {
    window.scrollY > 1000 && setScrollVisible(true);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollingStart);
    window.addEventListener('scrollend', scrollingEnd);
    return () => {
      window.removeEventListener('scroll', scrollingStart);
      window.removeEventListener('scrollend', scrollingEnd);
    };
  }, [scrollingStart, scrollingEnd]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="products mt-4">
      <Helmet>
        <meta
          name="keywords"
          content="фискальный накопитель, фн, фн замена, фн купить, фн 1.1, фн м, фн 36, фн атол, фн 15, фн касса, фн эвотор, фн цена, фн перерегистрация, фн штрих, фн инвента, фн накопитель, фн официальный, фн стоимость, фискальный накопитель купить, замена фискального накопителя, фискальный накопитель фн"
        />

        <meta
          name="description"
          content="Фискальные накопители в начилии и на заказ. Фискальный накопитель ФН-1.1М 36 месяцев устанавливается в онлайн-кассу. Устройство шифрует, записывает и хранит информацию о кассовых чеках. Из ФН информация в зашифрованном виде передается оператору фискальных данных (ОФД)."
        />
      </Helmet>

      <Container>
        <div className="wrap">
          <div className="products_main">
            <h3 className={cx('title', 'pb-2')}>Товары</h3>
            <Row>
              <Col
                xs={12}
                lg={Boolean(order.info?.products.length ? 9 : 12)}
              >
                {products.productsRequest || showModalInvoice ? (
                  <ContentLoader placeholderType="product-card" />
                ) : (
                  <div className={cx('products-cards')}>
                    {!!products.info?.length ? (
                      products.info
                        .filter(
                          (product) =>
                            product.availableStatus <
                            PRODUCTS_STATUSES.DO_NOT_DISPLAY,
                        )
                        .map((product) => (
                          <div
                            className={cx('products-card')}
                            key={product.id}
                          >
                            <ProductsCard
                              counter
                              product={product}
                              availableStatus={
                                product.availableStatus
                              }
                              indicator={false}
                              photo={product.images}
                              imageStyle={cx('products-img')}
                            />
                          </div>
                        ))
                    ) : (
                      <p className="pt-3 pb-3">нет товаров</p>
                    )}
                  </div>
                )}
              </Col>

              {!!order.info?.products.length && (
                <Col className="d-none d-lg-block" lg={3}>
                  <Aside />
                </Col>
              )}
            </Row>

            {scrollVisible && order.info?.products.length > 0 && (
              <button
                className={cx('scroll-btn')}
                onClick={handleScrollUp}
              >
                <img
                  className={styles['arrow-up']}
                  src={arrowUp}
                  alt="arrowUp"
                />
              </button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
});

ProductsPage.displayName = 'ProductsPage';
