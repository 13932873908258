import {
  TAuth,
  TDelivery,
  TDocs,
  TEdo,
  TOrder,
  TOrg,
  TProductsList,
  TUi,
} from '../models/global-state.model';
import { AUTH_FORM_TYPES } from './user.const';

export const initialAuth: TAuth = {
  authFormType: AUTH_FORM_TYPES.SIGN_IN,
  passwordError: '',
  isSignUpSuccess: false,
  isZeroOrganizations: false,
  multiLogin: {
    type: 'PK',
    userId: '',
    logins: [],
  },
};

export const initialUI: TUi = {
  loaderCounter: 0,
  menu: {
    isShow: false,
    isShowOnMobile: false,
  },
  modal: {
    isModalActive: false,
    showModalInvoice: false,
  },
  pageAlerts: [],
};

export const initialOrg: TOrg = {
  info: null,
  orgRequest: false,
  orgFailed: false,
};

export const initialDocs: TDocs = {
  info: null,
  docsRequest: false,
  docsFailed: false,
  docTypes: [],
};

export const initialProductsList: TProductsList = {
  info: null,
  productsRequest: false,
  productsFailed: false,
  product: {
    info: [],
    description: [],
    characteristic: [],
    productCardInfoRequest: false,
    productCardInfoFailed: false,
  },
};

export const initialOrder: TOrder = {
  ordersHistory: [],
  info: {
    products: [],
    totalPrice: 0,
  },
  startOrder: false,
  orderLoaderFlag: false,
  isSuccessOrder: false,
};

export const initialEdo: TEdo = {
  flow: {
    isEdoFlow: false,
    isEdoFlowRequest: false,
    isEdoFlowFailed: false,
  },
  check: {
    isEdoRegistered: false,
    isEdoRegisteredRequest: false,
    isEdoRegisteredFailed: false,
    isContinueNoEdoRegistered: false,
  },
  offer: {
    isOfferAccepted: false,
    isOfferAcceptedRequest: false,
    isOfferAcceptedFailed: false,
    isShowReady: false,
  },
};

export const initialDelivery: TDelivery = {
  isTariffLoading: false,
  isDeliveryFormReady: false,
  isDeliveryFormWasEdit: false,
  isDeliveryAddressError: false,
  deliveryData: {
    start: false,
    contactName: '',
    contactPhone: '',
    currentAddress: '',
    fiasId: null,
    dadataInfo: null,
    deliveryList: [],
    tariff: null,
    deliveryPrice: 0,
    deliveryType: 'Доставка',
    comments: '',
  },
};
