import { FC, memo, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppDispatch,
  getIsEdo,
  getIsEdoFlow,
  getIsOffer,
} from 'store';
import { setOrderLoaderFlag, setStartOrder } from 'store/order.slice';
import { setShowModalInvoice, showModal } from 'store/ui.slice';

import EdoRegistration from 'components/edo-registration/edo-registration';
import Modal from 'components/modal/modal';
import OfferAcceptance from 'components/offer-acceptance/offer-acceptance';

import {
  setContinueNoEdoRegistered,
  setIsShowReady,
} from '../../store/edo.slice';
import OfferAcceptanceReady from '../offer-acceptance/offer-acceptance-ready';
import styles from './modal-custom-edooffer.module.css';

const modalRoot = document.getElementById('modals') as HTMLElement;

const ModalCustomEdooffer: FC = memo(() => {
  const dispatch: AppDispatch = useDispatch();
  const { isOfferAccepted, isShowReady } = useSelector(getIsOffer);
  const { isEdoRegistered } = useSelector(getIsEdo);
  const { isEdoFlow } = useSelector(getIsEdoFlow);
  const [headerText, setHeaderText] = useState('');

  const onClose = useCallback(() => {
    dispatch(showModal(false));
    dispatch(setStartOrder(false));
    dispatch(setIsShowReady(false));
    dispatch(setOrderLoaderFlag(false));
    dispatch(setShowModalInvoice(false));
    !isEdoRegistered && dispatch(setContinueNoEdoRegistered(false));
  }, [dispatch, isEdoRegistered]);

  useEffect(() => {
    const withoutOffer = !isOfferAccepted;
    const withoutEdo = isEdoFlow && !isEdoRegistered;

    if (withoutOffer && withoutEdo) {
      setHeaderText('Согласие с Офертой и регистрация в ЭДО');
    } else if (withoutOffer) {
      setHeaderText('Согласие с Офертой');
    } else if (withoutEdo) {
      setHeaderText('Регистрация в ЭДО');
    } else {
      dispatch(showModal(false));
    }
  }, [dispatch, isOfferAccepted, isEdoRegistered, isEdoFlow]);

  return ReactDOM.createPortal(
    <Modal onClose={onClose} headerText={headerText}>
      <div className={styles['modal-row']}>
        {!isOfferAccepted && <OfferAcceptance />}

        {isOfferAccepted && isShowReady && (
          <div className={styles['modal-col']}>
            <OfferAcceptanceReady />
          </div>
        )}

        {isEdoFlow && !isEdoRegistered && (
          <div className={styles['modal-col']}>
            <EdoRegistration />
          </div>
        )}
      </div>
    </Modal>,
    modalRoot,
  );
});

ModalCustomEdooffer.displayName = 'ModalCustomEdooffer';

export default ModalCustomEdooffer;
