import classNames from 'classnames/bind';
import { FC, memo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { getOrder } from 'store';
import {
  downloadFormProcurationUrl,
  downloadRouteInstructionUrl,
} from 'urls';

import Aside from 'components/aside/aside';
import DownloadLink from 'components/download-link/download-link';
import DropdownList from 'components/dropdown-list/dropdown-list';
import NumberedList from 'components/numbered-list/numbered-list';

import basketIcon from 'images/icons/basket-gray.svg';
import boxIcon from 'images/icons/box-gray.svg';
import truckIcon from 'images/icons/truck-gray.svg';
import map from 'images/instruction/map.png';
import panorama from 'images/instruction/panorama.png';

import styles from './user-information.module.css';

const cx = classNames.bind(styles);

const serviceTip = [
  <p>
    <a
      href="https://cdn1.platformaofd.ru/lkfn-prod/docs/Оферта_ООО__ТД_Платформа.pdf?v=1"
      className={cx('link')}
      target="_blank"
      rel="noopener noreferrer"
    >
      Подпишитe
    </a>{' '}
    договор на поставку.
  </p>,
  <>
    <p>
      Убедитесь, что подключены к{' '}
      <a
        href="https://edo.platformaofd.ru/"
        target="_blank"
        className={cx('link')}
        rel="noopener noreferrer"
      >
        «Платформа ЭДО»
      </a>
      .
    </p>
    <p>Обмен документами с ТД Платформа происходит в ЭДО.</p>
    <br />
    <p>
      Пройдите регистрацию в сервисе «Платформа ЭДО» или отправьте
      приглашение
    </p>
    <p>ООО «Торговый дом Платформа» по</p>
    <p>
      <span className={cx('text-wrap')}>
        ID = 2VO-9704104605-7704010010000000000000000000000
      </span>{' '}
      для настройки роуминга с другим оператором.
    </p>
  </>,
];

const orderTip = [
  <>
    <p>
      Перейдите на сайт{' '}
      <a
        href="https://platformatd.ru"
        target="_blank"
        className={cx('link')}
        rel="noopener noreferrer"
      >
        platformatd.ru
      </a>
      , чтобы сделать заказ:
    </p>
    <ul className={cx('sub-list')}>
      <li>выберите модели ФН</li>
      <li>укажите кол-во выбранных моделей</li>
    </ul>
    <p>В заказе может быть несколько моделей/позиций.</p>
  </>,
  <>
    <p>
      Выберите доступный для вас способ получения заказа: Доставка или
      Самовывоз.
    </p>
    <p>Укажите получателя и контакты для связи.</p>
  </>,
  <p>
    Счет сформируется после оформления заказа и будет доступен для
    скачивания
    <br />
    через пару минут в разделе История заказов в строке заказа.
  </p>,
  <p>
    Перед оплатой счета обязательно проверьте параметры заказа
    <br />в счете и в строке заказа. Если допущена ошибка, то
    сформируйте новый заказ.
  </p>,
  <p>
    Заказ будет готов к отгрузке в день поступления денежных средств.
  </p>,
];

const pickupTip = [
  <p>
    Получатель заказа на складе или при доставке по указанному адресу
    должен подписать акт приема-передачи (1 экземпляр акта остается у
    получателя).
  </p>,
  <p>
    УПД – документы реализации для приемки ФН в Вашей учетной <br />{' '}
    системе — отправлены Вам в «Платформа ЭДО».
  </p>,
  <p>Сверьте УПД и фактически полученный заказ.</p>,
  <p>Подпишите УПД электронной подписью.</p>,
];

const pickupProblemTip = [
  <p>Не подписывайте УПД до устранения разногласий.</p>,
  <p>
    Составьте акт о расхождениях по форме ТОРГ2 (составить в
    присутствии курьера с его подписью, в экспедиторской расписке
    курьера указать, что заказ принят с расхождениями или со следами
    вскрытия).
  </p>,
  <p>Приложите фото (если применимо).</p>,
  <p className={cx('text-danger')}>
    Свяжитесь с Вашим менеджером для урегулирования вопроса.
  </p>,
];

const expertiseAndGuaranteeTip = [
  <p>
    Подготовьте ФН и паспорт – в паспорте обязательно должны быть
    заполнены разделы «Акт ввода ФН в эксплуатацию» и «Заявка на
    замену ФН по гарантии». Если у Вас возникли проблемы с заполнением
    паспорта или он утерян, обращайтесь в Службу поддержки{' '}
    <a
      href="mailto:info@platformatd.ru"
      target="_blank"
      rel="noreferrer"
      className={cx('link')}
    >
      info@platformatd.ru
    </a>
  </p>,
  <p>
    Заполните{' '}
    <a
      className={cx('link')}
      href="https://cdn1.platformaofd.ru/lkfn-prod/docs/Заявление_покупателя_на_замену_фн_с_приложением.doc"
    >
      заявление
    </a>{' '}
    на замену.
  </p>,
  <p>
    Отправьте ФН, паспорт и заявление нам по адресу Москва, ул. Тимура
    Фрунзе 16 стр 3, контактный номер телефона{' '}
    <a
      href="tel:+79099012267"
      target="_blank"
      rel="noreferrer"
      className={cx('link')}
    >
      +79099012267
    </a>
  </p>,
];

const rulesItems = [
  {
    id: 0,
    href: '#welcome',
    title: 'При первом использовании сервиса',
    content: <NumberedList data={serviceTip} />,
    icon: null,
  },
  {
    id: 1,
    title: 'Заказ',
    href: '#order',
    content: <NumberedList data={orderTip} />,
    icon: <img src={boxIcon} alt="иконка коробки" />,
  },
  {
    id: 2,
    title: 'Доставка',
    href: '#delivery',
    content: (
      <>
        <p className={cx('text-margin')}>
          Мы организуем доставку до указанного Вами адреса.
        </p>

        <p className={cx('text-margin')}>В форме заказа укажите:</p>

        <ul className={cx('sublist-default')}>
          <li className={cx('subitem-default')}>Адрес доставки</li>
          <li className={cx('subitem-default')}>ФИО получателя</li>
          <li className={cx('subitem-default')}>
            Контактный телефон: курьер при доставке будет использовать
            этот номер для связи.
          </li>
        </ul>

        <p className={cx('text-margin')}>
          Если доставка не включена в стоимость товара, то при
          оформлении заказа:
        </p>

        <ul className={cx('sublist-default')}>
          <li className={cx('subitem-default')}>
            Выберите подходящий тариф для доставки (в выпадающем
            списке в поле "Тариф" будет указана Транспортная компания,
            ориентировочный срок доставки и стоимость). Стоимость
            доставки будет выделена в счете отдельной строкой.
          </li>
        </ul>

        <p className={cx('text-margin', 'text-padding-right-large')}>
          Мы отправим заказ после поступления оплаты по счету.
          Отслеживайте заказ по трек-номеру (отразится в строке заказа
          в списке ваших заказов) на сайте:{' '}
          <a
            href="https://versta24.ru"
            target="_blank"
            rel="noreferrer"
            className={cx('link')}
          >
            versta24.ru
          </a>
        </p>
      </>
    ),
    icon: <img src={truckIcon} alt="иконка грузовика доставки" />,
  },
  {
    id: 3,
    title: 'Самовывоз',
    href: '#pickup',
    content: (
      <>
        <div>
          <DownloadLink
            link={downloadRouteInstructionUrl}
            payload={{
              responseType: 'arraybuffer',
            }}
            textLink="Как пройти - инструкция для печати"
            linkStyle="long"
            fileName="Как пройти - инструкция для печати.pdf"
            documentType="application/pdf"
            iconSrc={null}
            showedDownloadIcon={null}
          />
          <p
            className={cx('text-margin', 'text-padding-right-large')}
          >
            <span className={cx('text-bold')}>Адрес склада: </span> г.
            Москва, 4-я Кабельная ул., 6А (метро «Авиамоторная» Склад
            ООО «БетаПРО»).
          </p>
          <p
            className={cx('text-margin', 'text-padding-right-large')}
          >
            <span className={cx('text-bold')}>
              Время работы ПВЗ:{' '}
            </span>
            круглосуточно (забор заказа в СБ и ВС необходимо
            согласовать заранее с менеджером).
          </p>
          <p
            className={cx(
              'text-margin',
              'text-padding-right-large',
              'phone',
            )}
          >
            <span className={cx('text-bold')}>Телефоны: </span>{' '}
            <a className={cx('link')} href="tel:+79154884701">
              +7 (915) 488-47-01
            </a>{' '}
            (с 10 до 18, ПН-ПТ)
            <br />
            <a
              className={cx('link', 'mobile-tel-margin')}
              href="tel:+74956498899"
            >
              +7 (495) 649-88-99
            </a>{' '}
            (добавочный 1461)
          </p>
          <ul className={cx('sublist-default')}>
            <p className={cx('text-margin')}>
              В форме заказа при выборе опции самовывоз:
            </p>
            <li
              className={cx('subitem-default', 'subitem-margin-top')}
            >
              укажите ФИО сотрудника, который будет забирать груз или
              наименование транспортной компании, если вы привлекаете
              ее для забора груза
            </li>
            <li className={cx('subitem-default')}>
              контактный телефон
            </li>
          </ul>
          <p
            className={cx('text-margin', 'text-padding-right-small')}
          >
            Для получения товара на складе сотрудник или курьер ТК
            должны указать номер заказа на складе — FNR. Номер заказа
            сформируется после поступления оплаты по счету.
          </p>
          <p
            className={cx('text-margin', 'text-padding-right-small')}
          >
            Получить номер FNR Вы сможете в списке заказов ФН в
            дополнительной информации по заказу. Забрать заказ
            возможно при получении статуса «Готов к выдаче».
          </p>
        </div>
        <p className={cx('title-sublist')}>
          Если заказ забирает Транспортная компания (ТК):
        </p>
        <ul className={cx('sublist-default')}>
          <li className={cx('subitem-default')}>
            <span>
              Передайте доверенность от Вашей компании на ТК в офис
              <br /> «ТД Платформа» до отгрузки
            </span>
          </li>
          <li className={cx('subitem-default')}>
            <span>
              Курьер ТК должен предоставить доверенность на получение
              заказа от ТК (передается на склад)
            </span>
          </li>
          <li className={cx('subitem-default')}>
            <span>
              Для получения заказа на складе курьер должен сообщить
              номер заказа FNR
            </span>
          </li>
        </ul>
        <DownloadLink
          link={downloadFormProcurationUrl}
          payload={{
            responseType: 'arraybuffer',
          }}
          textLink="Форма доверенности ТК на бланке организации"
          linkStyle="long"
          fileName="Форма доверенности ТК на бланке организации.docx"
          documentType="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          iconSrc={null}
          showedDownloadIcon={null}
        />
        <p className={cx('title-sublist')}>
          Если заказ забирает сотрудник Вашей компании
        </p>
        <ul className={cx('sublist-default')}>
          <li className={cx('subitem-default')}>
            <span>Сообщите номер заказа на складе — FNR</span>
          </li>
          <li className={cx('subitem-default')}>
            <span>
              Предоставьте доверенность на получение ТМЦ от Вашей
              компании и/или печать
            </span>
          </li>
        </ul>
        <p className={cx('title-sublist')}>
          Как пройти в пункт самовывоза
        </p>
        <img
          src={map}
          className={cx('img', 'text-margin')}
          alt="Схема на карте"
        />
        <p className={cx('text-margin', 'text-padding-right-small')}>
          На проходной сказать, что «за заказом в Бета Про». При себе
          должен быть документ, удостоверяющий личность.
        </p>
        <p className={cx('text-margin', 'text-padding-right-small')}>
          После шлагбаума нужно повернуть направо, зайти во вторую
          дверь (угловая), по коридору направо до охраны.
        </p>
        <img
          src={panorama}
          className={cx('img')}
          alt="Панорама пункта выдачи заказа"
        />
      </>
    ),
    icon: <img src={basketIcon} alt="иконка корзины самовывоза" />,
  },
  {
    id: 4,
    href: '#get',
    title: 'Получение товара и обмен документами',
    content: (
      <>
        <NumberedList data={pickupTip} />
        <p className={cx('title-sublist')}>Расхождение при приемке</p>
        <NumberedList data={pickupProblemTip} />
      </>
    ),
    icon: null,
  },
  {
    id: 5,
    title: 'Экспертиза и гарантия',
    href: '#expertise-guarantee',
    content: (
      <>
        <p className={cx('text-margin')}>
          Если приобретенный у нас ФН неисправен, присылайте его нам
          для передачи производителю на экспертизу.
        </p>

        <NumberedList data={expertiseAndGuaranteeTip} />

        <p className={cx('text-margin', 'text-padding-right-large')}>
          Проведение экспертизы может занять от 3 дней до 1 месяца, по
          результатам производитель оформляет Заключение. Чтобы
          уточнить статус экспертизы, обращайтесь в Службу поддержки{' '}
          <a
            href="mailto:info@platformatd.ru"
            target="_blank"
            rel="noreferrer"
            className={cx('link')}
          >
            info@platformatd.ru
          </a>
        </p>

        <p className={cx('text-margin')}>
          Более подробные условия гарантийного обслуживания каждого
          производителя указаны в паспорте изделия.
        </p>
      </>
    ),
    icon: null,
  },
];

export const UserInformation: FC = memo(() => {
  const order = useSelector(getOrder);

  return (
    <div className="products mt-4">
      <Helmet>
        <meta
          name="keywords"
          content="фискальный накопитель, фн, фн замена, фн купить, фн 1.1, фн м, фн 36, фн атол, фн 15, фн касса, фн эвотор, фн цена, фн перерегистрация, фн штрих, фн инвента, фн накопитель, фн официальный, фн стоимость, фискальный накопитель купить, замена фискального накопителя, фискальный накопитель фн"
        />
        <meta
          name="description"
          content="Фискальный накопитель (ФН) устанавливается в онлайн-кассу. Устройство шифрует, записывает и хранит информацию о кассовых чеках. Из ФН информация в зашифрованном виде передается оператору фискальных данных (ОФД)."
        />
      </Helmet>
      <Container>
        <div className="wrap">
          <div className="products_main">
            <h3 className={`${styles.title} pb-2`}>Покупателям</h3>
            <Row>
              <Col
                xs={12}
                lg={Boolean(order.info?.products.length ? 9 : 12)}
              >
                <DropdownList dropdownData={rulesItems} />
              </Col>
              {!!order.info?.products.length && (
                <Col className="d-none d-lg-block" lg={3}>
                  <Aside />
                </Col>
              )}
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
});

UserInformation.displayName = 'UserInformation';
