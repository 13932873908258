import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getProductsList } from 'store';

export const useProducts = () => {
  const { id } = useParams();
  const location = useLocation();
  const products = useSelector(getProductsList);

  const isMainPage = useMemo(() => {
    return location.pathname === '/';
  }, [location.pathname]);

  const currentProduct = useMemo(() => {
    if (!products.info?.length) {
      return null;
    }

    return products.info.find((item) => item.id === Number(id));
  }, [id, products]);

  return { isMainPage, currentProduct };
};
