import classNames from 'classnames/bind';
import { FC, ReactNode, memo, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { addAlert, showLoader } from 'store/ui.slice';

import { Icon } from 'components/Icon';

import { TEN_SECONDS } from 'shared/const';
import useDownloadFile from 'shared/hooks/use-download-file';
import httpClient from 'shared/utils/http-client/http-client';

import styles from './download-link.module.css';

const cx = classNames.bind(styles);

interface DownloadLinkProps {
  textLink: string;
  link: string;
  payload: { responseType: string };
  linkStyle: string;
  fileName?: string;
  documentType: string;
  iconSrc?: string | ReactNode;
  showedDownloadIcon?: boolean | null;
  isDisabled?: boolean;
}

const DownloadLink: FC<DownloadLinkProps> = memo(
  ({
    textLink,
    link,
    payload,
    linkStyle,
    fileName,
    documentType,
    iconSrc,
    showedDownloadIcon,
    isDisabled,
  }) => {
    const ref = useRef(null);
    const dispatch = useDispatch();

    const preDownloading = useCallback(
      () => dispatch(showLoader(true)),
      [dispatch],
    );

    const postDownloading = useCallback(
      () => dispatch(showLoader(false)),
      [dispatch],
    );

    const onErrorDownloadFile = useCallback(() => {
      window.scrollTo(0, 0);
      dispatch(
        addAlert({
          text: 'Документ не найден',
          variant: 'danger',
          lifetime: TEN_SECONDS,
        }),
      );
      postDownloading();
    }, [dispatch, postDownloading]);

    const downloadLink = useCallback(
      () =>
        httpClient.get(link, {
          ...payload,
          responseType: 'arraybuffer',
        }),
      [link, payload],
    );

    const { url, download, name } = useDownloadFile({
      refEl: ref,
      apiDefinition: downloadLink,
      preDownloading,
      postDownloading,
      onError: onErrorDownloadFile,
      getFileName: fileName,
      documentType,
    });

    return (
      <div
        className={cx({
          wrapper: linkStyle === 'long',
          disabled: isDisabled,
        })}
      >
        {linkStyle === 'long' && (
          <div className={cx('doc-img')}>
            <Icon name="doc" width="26" height="32" />
          </div>
        )}

        <a
          href={url}
          download={name}
          style={{ display: 'none' }}
          target="_blank"
          ref={ref}
          rel="noreferrer"
        >
          Ссылка на скачивание
        </a>

        <button
          type="button"
          data-testid="his-order-subitem-link-6"
          onClick={download}
          disabled={isDisabled}
          className={cx({
            'download-link-long': linkStyle === 'long',
            'download-link-short': linkStyle === 'short',
            'download-btn': textLink === 'Скачать счет',
          })}
        >
          {linkStyle === 'icon' && (
            <>
              {!isDisabled && (
                <div
                  className={cx('icon', {
                    hidden: !showedDownloadIcon && !isDisabled,
                  })}
                >
                  <Icon name="downloadDoc" width="31" height="32" />
                </div>
              )}

              <div
                className={cx('icon', {
                  hidden: showedDownloadIcon && !isDisabled,
                })}
              >
                {iconSrc}
              </div>
            </>
          )}

          {linkStyle !== 'icon' && (
            <>
              {textLink}
              {textLink !== 'Скачать счет' && (
                <Icon name="download" width="30" height="30" />
              )}
            </>
          )}
        </button>
      </div>
    );
  },
);

DownloadLink.displayName = 'DownloadLink';

export default DownloadLink;
