import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialDelivery } from 'shared/const/initial-state.const';
import { TDelivery } from 'shared/models/global-state.model';

const initialState: TDelivery = { ...initialDelivery };

const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    setDeliveryData(
      state,
      action: PayloadAction<TDelivery['deliveryData']>,
    ) {
      state.deliveryData = action.payload;
      state.isDeliveryFormWasEdit = true;
    },
    setTariffLoading(state, action: PayloadAction<boolean>) {
      state.isTariffLoading = action.payload;
    },
    setDeliveryFormReady(state, action: PayloadAction<boolean>) {
      state.isDeliveryFormReady = action.payload;
    },
    setDeliveryAddressError(state, action: PayloadAction<boolean>) {
      state.isDeliveryAddressError = action.payload;
    },
    deliveryPriceReset(state) {
      state.deliveryData.deliveryPrice = 0;
    },
  },
});

export const {
  setDeliveryData,
  setTariffLoading,
  setDeliveryFormReady,
  setDeliveryAddressError,
  deliveryPriceReset,
} = deliverySlice.actions;

export default deliverySlice.reducer;
