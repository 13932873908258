import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { orgUrl } from 'urls';

import { initialOrg } from 'shared/const/initial-state.const';
import { TOrg } from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import { logOutUser } from './auth.slice';

const initialState: TOrg = { ...initialOrg };

const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    getOrgStart(state) {
      state.orgRequest = true;
      state.orgFailed = false;
    },
    getOrgSuccess(state, action: PayloadAction<TOrg['info']>) {
      state.info = action.payload;
      state.orgRequest = false;
    },
    getOrgFailed(state) {
      state.orgFailed = true;
      state.orgRequest = false;
    },
    clearOrg(state) {
      state.info = null;
    },
  },
});

export const { getOrgStart, getOrgSuccess, getOrgFailed, clearOrg } =
  orgSlice.actions;
export default orgSlice.reducer;

export const getOrg = createAsyncThunk(
  'org/getOrg',
  async (_, { dispatch, rejectWithValue }) => {
    const orgId = multiLoginStorage.getOrgId();
    dispatch(getOrgStart());
    try {
      if (orgId) {
        const { data } = await httpClient.get(orgUrl(orgId));
        dispatch(getOrgSuccess(data));
        return data;
      }
    } catch (error) {
      dispatch(getOrgFailed());
      const err = error as IError;

      if (err.response.status === 401) {
        dispatch(logOutUser());
      }

      return rejectWithValue(err.response.data.message);
    }
  },
);
