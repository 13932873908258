import classNames from 'classnames/bind';
import {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AppDispatch } from 'store';
import { setShowModalInvoice } from 'store/ui.slice';

import cross from 'images/icons/cross.svg';

import styles from './modal-pay-order.module.css';

const cx = classNames.bind(styles);

interface IModalPayOrderProps {
  modalActive: boolean;
  setModalActive: Dispatch<SetStateAction<boolean>>;
  children: JSX.Element;
}

export const ModalPayOrder: FC<IModalPayOrderProps> = memo(
  ({ modalActive, setModalActive, children }) => {
    const dispatch: AppDispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleCloseModal = useCallback(() => {
      setModalActive(false);
      searchParams.delete('payment');
      setSearchParams(searchParams);
    }, [setModalActive, searchParams, setSearchParams]);

    useEffect(() => {
      if (searchParams.get('payment') === 'true') {
        setModalActive(true);
        dispatch(setShowModalInvoice(false));
      }
    }, [dispatch, searchParams, setModalActive]);

    return (
      <div className={cx('modal', { 'modal-active': modalActive })}>
        <div
          className={cx('content', { 'content-active': modalActive })}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles['content-header']}>
            Оплата заказа
            <img
              src={cross}
              className={styles.cross}
              onClick={handleCloseModal}
              alt="cross"
            />
          </div>

          {children}
        </div>
      </div>
    );
  },
);

ModalPayOrder.displayName = 'ModalPayOrder';
