import {
  ActionCreatorWithPayload,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { getfileUrl, productsUrl } from 'urls';

import { PRODUCTS_STATUSES } from 'shared/const';
import { initialProductsList } from 'shared/const/initial-state.const';
import {
  FileProduct,
  TProductsList,
} from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import { getHtmlData } from '../shared/utils/read-html.util';
import { logOutUser } from './auth.slice';
import { AppDispatch } from './index';
import { addAlert, showLoader } from './ui.slice';

type TDispatchType =
  | ActionCreatorWithPayload<
      Record<string, string>,
      'products/getDescSuccess'
    >
  | ActionCreatorWithPayload<
      Record<string, string>,
      'products/getCharSuccess'
    >;

const initialState: TProductsList = {
  ...initialProductsList,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    getProductsStart(state) {
      state.productsRequest = true;
      state.productsFailed = false;
    },
    getProductsSuccess(
      state,
      action: PayloadAction<TProductsList['info']>,
    ) {
      state.info = action.payload;
      state.productsRequest = false;
    },
    getProductsFailed(state) {
      state.productsFailed = true;
      state.productsRequest = false;
    },
    getInfo(state) {
      state.product.productCardInfoRequest = true;
      state.product.productCardInfoFailed = false;
    },
    getDocSuccess(state, action) {
      state.product.info.push(action.payload);
      state.product.productCardInfoRequest = false;
    },
    getDescSuccess(
      state,
      action: PayloadAction<Record<string, string>>,
    ) {
      state.product.description.push(action.payload);
      state.product.productCardInfoRequest = false;
    },
    getCharSuccess(
      state,
      action: PayloadAction<Record<string, string>>,
    ) {
      state.product.characteristic.push(action.payload);
      state.product.productCardInfoRequest = false;
    },
    getImagesFailed(state) {
      state.product.productCardInfoFailed = true;
      state.product.productCardInfoRequest = false;
    },
  },
});

export const getProducts = createAsyncThunk(
  'products/getProducts',
  async (_, { dispatch, rejectWithValue }) => {
    const orgId = multiLoginStorage.getOrgId();
    dispatch(getProductsStart());
    dispatch(showLoader(true));

    try {
      const { data } = await httpClient.get<TProductsList['info']>(
        productsUrl(orgId),
      );

      const products =
        data &&
        data.filter((item) => {
          return (
            item.availableStatus !== PRODUCTS_STATUSES.DO_NOT_DISPLAY
          );
        });

      dispatch(getProductsSuccess(products));
    } catch (error) {
      dispatch(getProductsFailed());
      const err = error as IError;

      if (err.response.status === 500) {
        window.location.href = '/error500';
        return rejectWithValue('Server Error');
      }

      if (err.response.status === 401) {
        dispatch(logOutUser());
        return rejectWithValue('Unauthorized');
      }

      if (!!err.response.status) {
        dispatch(
          addAlert({
            text: 'Ошибка получения товаров',
            variant: 'danger',
          }),
        );
      }

      return rejectWithValue('Error fetching products');
    } finally {
      dispatch(showLoader(false));
    }
  },
);

async function getProductInformation(
  product: FileProduct,
  typeDispatch: TDispatchType,
  dispatch: AppDispatch,
  id: string,
) {
  const obj: Record<string, string> = {};

  try {
    const htmlCode = await getHtmlData<string>({
      url: product.fileUrl,
      onError: () => {
        dispatch(
          addAlert({
            text: 'Ошибка получения данных',
            variant: 'danger',
          }),
        );
      },
    });

    obj[id] = htmlCode ?? '';
    dispatch(typeDispatch(obj));
  } catch (e) {
    dispatch(
      addAlert({
        text: 'Ошибка получения данных',
        variant: 'danger',
      }),
    );
  }
}

async function getProductInfoFileId(
  item: FileProduct,
  dispatch: AppDispatch,
  id: string,
) {
  const document: Record<string, FileProduct> = {};

  try {
    if (item.fileTypeId === 8) {
      document[id] = item;
      dispatch(getDocSuccess(document));
    }

    if (item.fileTypeId === 2) {
      await getProductInformation(item, getDescSuccess, dispatch, id);
    }

    if (item.fileTypeId === 3) {
      await getProductInformation(item, getCharSuccess, dispatch, id);
    }
  } catch (error) {
    const err = error as IError;

    if (err?.response?.status === 401) {
      dispatch(logOutUser());
    }

    dispatch(
      addAlert({
        text: 'Ошибка получения файлов продукта',
        variant: 'danger',
      }),
    );
  }
}

export const getProductInfo = (id: string, typeID: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getInfo());
    dispatch(showLoader(true));

    try {
      const url = `${getfileUrl}/id/${id}/file/type/id/${typeID}`;
      const { data } = await httpClient.get(url);

      for (const item of data) {
        await getProductInfoFileId(item, dispatch, id);
      }
    } catch (error) {
      dispatch(
        addAlert({
          text: 'Ошибка получения файлов продукта по id',
          variant: 'danger',
        }),
      );
    } finally {
      dispatch(showLoader(false));
    }
  };
};

export const {
  getProductsStart,
  getProductsSuccess,
  getProductsFailed,
  getInfo,
  getDocSuccess,
  getDescSuccess,
  getCharSuccess,
  getImagesFailed,
} = productsSlice.actions;

export default productsSlice.reducer;
