import { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOffer } from 'store';
import {
  setIsShowReady,
  setOfferAccepted,
  setOfferAcceptedSuccess,
} from 'store/edo.slice';

import styles from '../modal-custom-edooffer/modal-custom-edooffer.module.css';
import OfferAcceptanceForm from './offer-acceptance-form';

const OfferAcceptance: FC = memo(() => {
  const { isOfferAccepted } = useSelector(getIsOffer);
  const dispatch = useDispatch();

  const handleShowReady = useCallback(() => {
    dispatch(setIsShowReady(true));
    dispatch(setOfferAccepted());
    dispatch(setOfferAcceptedSuccess(true));
  }, [dispatch]);

  if (!isOfferAccepted) {
    return (
      <div className={styles['modal-col']}>
        <OfferAcceptanceForm onShowReady={handleShowReady} />
      </div>
    );
  }

  return null;
});

OfferAcceptance.displayName = 'OfferAcceptance';

export default OfferAcceptance;
