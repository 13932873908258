import React, { FC, memo, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppDispatch,
  getDeliveryState,
  getModals,
  getMultiLogin,
  getOrder,
} from 'store';
import { setOrderLoaderFlag, setStartOrder } from 'store/order.slice';
import { addAlert, showLoader } from 'store/ui.slice';
import { currency } from 'utils';

import CustomBtn from 'components/custom-btn/custom-btn';
import OrderDelivery from 'components/order-delivery/order-delivery';
import OrderItems from 'components/order-items/order-items';

import { CONTENT, ONE_MINUTE } from 'shared/const';
import { useAuth } from 'shared/hooks/use-auth';
import { tokens } from 'shared/utils/tokens.util';

import styles from './order.module.css';

export const Order: FC = memo(() => {
  const auth = useAuth();
  const isAuth = tokens.getAccess();
  const order = useSelector(getOrder);
  const { isTariffLoading } = useSelector(getDeliveryState);
  const { deliveryData } = useSelector(getDeliveryState);
  const { logins } = useSelector(getMultiLogin);
  const { showModalInvoice } = useSelector(getModals);
  const dispatch: AppDispatch = useDispatch();

  const handleOrderLoaderFlagToggle = useCallback(
    (val: boolean) => {
      dispatch(setOrderLoaderFlag(val));
    },
    [dispatch],
  );

  const startOrderOn = useCallback(() => {
    if (!isAuth) {
      return auth?.logIn();
    }

    if (logins.length === 0) {
      return dispatch(
        addAlert({
          text: CONTENT.auth.login.zeroOrganizations,
          variant: 'warning',
          lifetime: ONE_MINUTE,
        }),
      );
    }

    dispatch(showLoader(true));
    handleOrderLoaderFlagToggle(true);
    dispatch(setStartOrder(true));
  }, [
    handleOrderLoaderFlagToggle,
    dispatch,
    isAuth,
    auth,
    logins.length,
  ]);

  const startOrderOff = useCallback(() => {
    dispatch(setStartOrder(false));
    dispatch(showLoader(false));
  }, [dispatch]);

  return (
    <div className="order mt-4 mb-4">
      <Container>
        <div className="main">
          <div className="d-md-flex">
            <OrderItems aside={false} />

            <OrderDelivery
              aside={false}
              onOrderLoaderFlagToggle={handleOrderLoaderFlagToggle}
              startOrderOff={startOrderOff}
            />
          </div>

          <div
            className={`${styles.block} mt-3 d-flex justify-content-end`}
          >
            <div>
              <div
                className={`${styles['items-price']} mt-3 d-flex justify-content-end align-items-baseline`}
              >
                <span>Сумма заказа:</span>

                <span className={`${styles.price} ms-3`}>
                  {!!order.info?.totalPrice && (
                    <span className={`${styles.price} ms-3`}>
                      {`${currency(
                        order.info.totalPrice +
                          deliveryData.deliveryPrice,
                      )} ₽`}
                    </span>
                  )}
                </span>
              </div>

              <div className="mt-4">
                <CustomBtn
                  theme="edo"
                  type="button"
                  text={isAuth ? 'Заказать' : 'Авторизуйтесь'}
                  onClick={startOrderOn}
                  testId="order-btn-1"
                  loading={
                    order.orderLoaderFlag ||
                    isTariffLoading ||
                    showModalInvoice
                  }
                  isLink={false}
                  customCls={null}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
});

Order.displayName = 'Order';
