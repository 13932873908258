import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'store';

import App from 'components/app/app';

import { AuthContextProvider } from 'shared/hooks/use-auth';

import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </Provider>,
);

reportWebVitals();
