import { configureStore } from '@reduxjs/toolkit';

import authSlice from './auth.slice';
import deliverySlice from './delivery.slice';
import docsSlice from './docs.slice';
import edoSlice from './edo.slice';
import orderSlice from './order.slice';
import orgSlice from './org.slice';
import productsSlice from './products.slice';
import uiSlice from './ui.slice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    iu: uiSlice,
    org: orgSlice,
    docs: docsSlice,
    products: productsSlice,
    order: orderSlice,
    edo: edoSlice,
    delivery: deliverySlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const getAuth = (state: RootState) => state.auth;
export const getLoader = (state: RootState) => state.iu.loaderCounter;
export const getMenu = (state: RootState) => state.iu.menu;
export const getPageAlerts = (state: RootState) =>
  state.iu.pageAlerts;
export const getOrgState = (state: RootState) => state.org;
export const getDocsState = (state: RootState) => state.docs;
export const getProductsList = (state: RootState) => state.products;
export const getProduct = (state: RootState) =>
  state.products.product;
export const getOrder = (state: RootState) => state.order;
export const getIsEdoFlow = (state: RootState) => state.edo.flow;
export const getIsEdo = (state: RootState) => state.edo.check;
export const getIsOffer = (state: RootState) => state.edo.offer;
export const getModals = (state: RootState) => state.iu.modal;
export const getDeliveryState = (state: RootState) => state.delivery;
export const getMultiLogin = (state: RootState) =>
  state.auth.multiLogin;
