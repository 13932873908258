import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { docsUrl } from 'urls';

import { initialDocs } from 'shared/const/initial-state.const';
import { TDocs } from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import { logOutUser } from './auth.slice';
import { showLoader } from './ui.slice';

const initialState: TDocs = { ...initialDocs };

const docsSlice = createSlice({
  name: 'docs',
  initialState,
  reducers: {
    getDocsStart(state) {
      state.docsRequest = true;
      state.docsFailed = false;
    },
    getDocsSuccess(state, action: PayloadAction<TDocs['info']>) {
      state.info = action.payload;
      state.docsRequest = false;
    },
    getDocsFailed(state) {
      state.docsFailed = true;
      state.docsRequest = false;
    },
    setDocTypes(state, action) {
      state.docTypes = action.payload;
    },
    clearDocs(state) {
      state.info = [];
    },
  },
});

export const getDocs = createAsyncThunk(
  'docs/getDocs',
  async (_, { dispatch, rejectWithValue }) => {
    const orgId = multiLoginStorage.getOrgId();
    dispatch(getDocsStart());
    dispatch(showLoader(true));
    try {
      if (orgId) {
        const { data } = await httpClient.get(docsUrl(orgId));
        dispatch(getDocsSuccess(data));
        return data;
      }
    } catch (error) {
      dispatch(getDocsFailed());
      const err = error as IError;

      if (err?.response?.status === 401) {
        dispatch(logOutUser());
      }

      return rejectWithValue(err.response.data.message);
    } finally {
      dispatch(showLoader(false));
    }
  },
);

export const {
  getDocsStart,
  getDocsSuccess,
  setDocTypes,
  getDocsFailed,
  clearDocs,
} = docsSlice.actions;

export default docsSlice.reducer;
