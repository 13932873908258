import { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, getIsEdo } from 'store';
import { setOrderLoaderFlag, setStartOrder } from 'store/order.slice';
import { showModal } from 'store/ui.slice';

import offerImg from 'images/confirm_offer.svg';
import okImg from 'images/ok.svg';

import styles from './offer-acceptance-ready.module.css';

const OfferAcceptanceReady: FC = memo(() => {
  const { isContinueNoEdoRegistered } = useSelector(getIsEdo);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (isContinueNoEdoRegistered) {
      dispatch(setStartOrder(true));
      dispatch(setOrderLoaderFlag(true));
      dispatch(showModal(false));
    }
  }, [dispatch, isContinueNoEdoRegistered]);

  return (
    <div className={styles['accept-ready']}>
      <div className={styles.top}>
        <img className="mx-auto" src={offerImg} alt="" />
      </div>

      <div className={styles.bottom}>
        <img src={okImg} alt="" />
        <div>Принято</div>
      </div>
    </div>
  );
});

OfferAcceptanceReady.displayName = 'OfferAcceptanceReady';

export default OfferAcceptanceReady;
