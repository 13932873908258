import { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrder } from 'store';
import { setDeliveryData } from 'store/delivery.slice';
import { changeOrder, setSuccessOrder } from 'store/order.slice';
import { dateFormatter } from 'utils';

import HistoryOrdersItem from 'components/history-orders-item/history-orders-item';

import { Order } from 'shared/models/order.model';

interface IHistoryOrderList {
  filteredOrders: Order[];
}

const HistoryOrderList: FC<IHistoryOrderList> = memo(
  ({ filteredOrders }) => {
    const { isSuccessOrder } = useSelector(getOrder);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        setSuccessOrder(!!localStorage.getItem('openInvoiceOrder')),
      );
    }, [dispatch]);

    useEffect(() => {
      if (isSuccessOrder) {
        dispatch(
          changeOrder({
            products: [],
            totalPrice: 0,
          }),
        );
        dispatch(
          setDeliveryData({
            contactName: '',
            contactPhone: '',
            currentAddress: '',
            fiasId: null,
            dadataInfo: null,
            deliveryList: [],
            tariff: null,
            deliveryPrice: 0,
            deliveryType: 'Доставка',
            comments: '',
          }),
        );
      }
    }, [dispatch, isSuccessOrder]);

    return (
      <div>
        {filteredOrders.map(
          (
            {
              deliveryTypeId,
              orderId,
              orderDate,
              person,
              price,
              contactName,
              contactPhone,
              orderDeliveryAddress,
              trackNumber,
              storageId,
              deliveryStatusName,
              statusName,
              payStatusName,
              payRemain,
              addressDesc,
              DOC_STATUS,
              deliveryPrice,
              vendorName,
            },
            index,
          ) => {
            let deliveryTypeName;

            if (deliveryTypeId === 0) {
              deliveryTypeName = 'Самовывоз';
            } else {
              deliveryTypeName = 'Доставка';
            }

            if (index === 0) {
              return (
                <HistoryOrdersItem
                  key={orderId}
                  num={orderId}
                  date={`${dateFormatter(
                    orderDate,
                    'dd.mm.yyyy',
                  )} в ${dateFormatter(orderDate, 'time')}`}
                  author={person}
                  delivery={deliveryTypeName}
                  sum={`${price} ₽`}
                  contactName={contactName}
                  contactPhone={contactPhone}
                  deliveryAddress={orderDeliveryAddress}
                  trackNumber={trackNumber}
                  storageId={storageId}
                  orderId={orderId}
                  deliveryStatusName={deliveryStatusName}
                  statusName={statusName}
                  payStatusName={payStatusName}
                  price={price}
                  payRemain={payRemain}
                  adressDesc={addressDesc}
                  documentStatusName={DOC_STATUS}
                  deliveryPrice={deliveryPrice}
                  vendorName={vendorName}
                  isNewOrder={isSuccessOrder}
                />
              );
            }
            return (
              <HistoryOrdersItem
                key={orderId}
                num={orderId}
                date={`${dateFormatter(
                  orderDate,
                  'dd.mm.yyyy',
                )} в ${dateFormatter(orderDate, 'time')}`}
                author={person}
                delivery={deliveryTypeName}
                sum={`${price} ₽`}
                contactName={contactName}
                contactPhone={contactPhone}
                deliveryAddress={orderDeliveryAddress}
                trackNumber={trackNumber}
                storageId={storageId}
                orderId={orderId}
                deliveryStatusName={deliveryStatusName}
                statusName={statusName}
                payStatusName={payStatusName}
                price={price}
                payRemain={payRemain}
                adressDesc={addressDesc}
                documentStatusName={DOC_STATUS}
                deliveryPrice={deliveryPrice}
                vendorName={vendorName}
              />
            );
          },
        )}
      </div>
    );
  },
);

HistoryOrderList.displayName = 'HistoryOrderList';

export default HistoryOrderList;
