import React, { FC, memo, useCallback } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, getAuth } from 'store';
import { delAlert } from 'store/ui.slice';

import { useAuth } from 'shared/hooks/use-auth';

import styles from './page-alert.module.css';

interface IPageAlertProps {
  text: string;
  variant: string;
  index: number;
}

const PageAlert: FC<IPageAlertProps> = memo(
  ({ text, variant, index }) => {
    const dispatch: AppDispatch = useDispatch();
    const { isSignUpSuccess, isZeroOrganizations } =
      useSelector(getAuth);
    const auth = useAuth();

    const onClose = useCallback(() => {
      dispatch(delAlert(index));
    }, [dispatch, index]);

    return (
      <Alert
        data-testid={`alert-${text}`}
        className={styles['fn-alert']}
        variant={variant}
        onClose={onClose}
        dismissible
      >
        <span dangerouslySetInnerHTML={{ __html: text }} />

        {isSignUpSuccess && (
          <span className={styles.auth} onClick={() => auth?.logIn()}>
            авторизуйтесь под своими учетными данными
          </span>
        )}

        {isZeroOrganizations && (
          <a
            className={styles.auth}
            href="https://lk.platformaofd.ru/web/registration/certify"
            target="_blank"
            rel="noreferrer"
          >
            кабинете Платформа ОФД
          </a>
        )}
      </Alert>
    );
  },
);

PageAlert.displayName = 'PageAlert';

export default PageAlert;
