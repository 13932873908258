import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { multiLoginUrl } from 'urls';

import { CONTENT, ONE_MINUTE } from 'shared/const';
import { initialAuth } from 'shared/const/initial-state.const';
import { TAuth } from 'shared/models/global-state.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/http-client/http-client';
import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';
import { tokens } from 'shared/utils/tokens.util';

import { addAlert, showLoader } from './ui.slice';

const initialState: TAuth = {
  ...initialAuth,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeAuthFormType(state, action: PayloadAction<string>) {
      state.authFormType = action.payload;
    },
    changePasswordError(state, action: PayloadAction<string>) {
      state.passwordError = action.payload;
    },
    setSignUpStatus(state) {
      state.isSignUpSuccess = true;
    },
    setZeroOrganizations(state) {
      state.isZeroOrganizations = true;
    },
    setMultiLoginData(
      state,
      action: PayloadAction<TAuth['multiLogin']>,
    ) {
      state.multiLogin = action.payload;
    },
  },
});

export const logOutUser = () => {
  return () => {
    const url = new URL(window.location.href);

    if (url.searchParams.get('id')) {
      window.location.href = '/';
    }

    tokens.removeAll();
    multiLoginStorage.removeOrgId();
  };
};

export const getOrganization = createAsyncThunk(
  'auth/getOrganization',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(showLoader(true));

    try {
      const { data } = await httpClient.get<TAuth['multiLogin']>(
        multiLoginUrl,
      );

      switch (data.logins.length) {
        case 0:
          dispatch(setZeroOrganizations());
          dispatch(
            addAlert({
              text: CONTENT.auth.login.zeroOrganizations,
              variant: 'warning',
              lifetime: ONE_MINUTE,
            }),
          );
          break;
        case 1:
          multiLoginStorage.setOrgId(data.logins[0].orgId);
          break;
        default:
          break;
      }

      dispatch(setMultiLoginData(data));
    } catch (error) {
      const err = error as IError;

      if (err.response.status === 500) {
        window.location.href = '/error500';
        return rejectWithValue('Server Error');
      }

      if (err.response.status === 401) {
        dispatch(logOutUser());
        return rejectWithValue('Unauthorized');
      }

      if (!!err.response.status) {
        dispatch(
          addAlert({
            text: 'Ошибка получения мультилогина',
            variant: 'warning',
          }),
        );
      }

      return rejectWithValue('Error fetching multiLogin');
    } finally {
      dispatch(showLoader(false));
    }
  },
);

export const {
  changeAuthFormType,
  changePasswordError,
  setSignUpStatus,
  setZeroOrganizations,
  setMultiLoginData,
} = authSlice.actions;

export default authSlice.reducer;
