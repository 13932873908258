import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialUI } from 'shared/const/initial-state.const';
import { TUi } from 'shared/models/global-state.model';

const initialState: TUi = { ...initialUI };

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showLoader(state, action: PayloadAction<boolean>) {
      state.loaderCounter += action.payload ? 1 : -1;
    },
    showMenu: (state, action: PayloadAction<boolean>) => {
      state.menu.isShow = action.payload;
    },
    showMenuOnMobile: (state, action: PayloadAction<boolean>) => {
      state.menu.isShowOnMobile = action.payload;
    },
    showModal(state, action: PayloadAction<boolean>) {
      state.modal.isModalActive = action.payload;
    },
    setShowModalInvoice(state, action: PayloadAction<boolean>) {
      state.modal.showModalInvoice = action.payload;
    },
    addAlert(
      state,
      action: PayloadAction<TUi['pageAlerts'][number]>,
    ) {
      window.scrollTo(0, 0);
      const index = state.pageAlerts.findIndex(
        (alert) => alert.text === action.payload.text,
      );

      index === -1
        ? state.pageAlerts.push(action.payload)
        : (state.pageAlerts[index] = action.payload);
    },
    delAlert(state, action: PayloadAction<number>) {
      state.pageAlerts.splice(action.payload, 1);
    },
    delAlertByText(state, action: PayloadAction<string>) {
      state.pageAlerts = state.pageAlerts.filter(
        (alert) => alert.text !== action.payload,
      );
    },
  },
});

export const {
  showLoader,
  showMenu,
  showMenuOnMobile,
  showModal,
  setShowModalInvoice,
  addAlert,
  delAlert,
  delAlertByText,
} = uiSlice.actions;

export default uiSlice.reducer;
