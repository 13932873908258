import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialOrder } from 'shared/const/initial-state.const';
import { TOrder } from 'shared/models/global-state.model';

const initialState: TOrder = { ...initialOrder };

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    changeOrder(state, action: PayloadAction<TOrder['info']>) {
      sessionStorage.setItem('order', JSON.stringify(action.payload));
      state.info = action.payload;
    },
    setStartOrder(state, action: PayloadAction<boolean>) {
      state.startOrder = action.payload;
    },
    setOrderLoaderFlag(state, action: PayloadAction<boolean>) {
      state.orderLoaderFlag = action.payload;
    },
    setOrders(state, action: PayloadAction<TOrder['ordersHistory']>) {
      state.ordersHistory = [...action.payload];
    },

    addToOrders(
      state,
      action: PayloadAction<TOrder['ordersHistory']>,
    ) {
      state.ordersHistory = action.payload;
    },

    clearOrders(state) {
      state.ordersHistory = [];
    },
    setSuccessOrder(state, action: PayloadAction<boolean>) {
      state.isSuccessOrder = action.payload;
    },
  },
});

export const {
  changeOrder,
  setStartOrder,
  setOrderLoaderFlag,
  setOrders,
  addToOrders,
  clearOrders,
  setSuccessOrder,
} = orderSlice.actions;
export default orderSlice.reducer;
