import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { getDocsState } from 'store';
import { getOfferUrl } from 'urls';

import DownloadLink from 'components/download-link/download-link';

import { multiLoginStorage } from 'shared/utils/navigate-to-multi-login.utils';

import styles from './documents.module.css';

const Documents: FC = memo(() => {
  const docs = useSelector(getDocsState);
  const orgId = multiLoginStorage.getOrgId();

  return (
    <div className={styles.documents}>
      {!!docs.info?.length ? (
        <>
          {docs.info.map((item) => (
            <DownloadLink
              key={item.docId}
              link={`${getOfferUrl(orgId)}?docType=${item.docTypeId}`}
              payload={{ responseType: 'blob' }}
              textLink={item.docName}
              linkStyle="long"
              fileName={`${item.docName}.pdf`}
              documentType="application/pdf"
            />
          ))}
        </>
      ) : (
        <p>нет документов</p>
      )}
    </div>
  );
});

Documents.displayName = 'Documents';

export default Documents;
