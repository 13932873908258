import { KeycloakLoginOptions } from 'keycloak-js';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { showLoader } from 'store/ui.slice';

import {
  MIN_VALIDITY_SECONDS,
  keycloak,
} from 'shared/const/keycloak.const';
import { tokens } from 'shared/utils/tokens.util';

interface IAuthState {
  isDataLoading: boolean;
  isAuthenticated: boolean;
  loadingError: string;
}

interface IAuthContext extends IAuthState {
  init: () => void;
  logIn: (params?: KeycloakLoginOptions) => void;
  logOut: () => void;
  getProfile: () => void;
  updateToken: () => void;
}

const AuthContext = createContext<IAuthContext | null>(null);

export const AuthContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = useState<IAuthState>({
    isAuthenticated: false,
    isDataLoading: true,
    loadingError: '',
  });

  const dispatch = useDispatch();

  const isKeycloakInitialized = useRef<boolean>(false);

  const handleIfAuth = useCallback(
    (access: string, refresh: string) => {
      setState((prev) => ({
        ...prev,
        isAuthenticated: true,
        loadingError: '',
      }));
      tokens.setAccess(access);
      tokens.setRefresh(refresh);
    },
    [],
  );

  const handleIfNotAuth = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isAuthenticated: false,
      loadingError: '',
    }));
  }, []);

  const handleAuthError = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isAuthenticated: false,
      loadingError: 'Не удалось проверить аутентификацию',
    }));
  }, []);

  const init = useCallback(() => {
    if (isKeycloakInitialized.current) return;

    isKeycloakInitialized.current = true;

    setState((prev) => ({ ...prev, isDataLoading: true }));

    dispatch(showLoader(true));

    return keycloak
      .init({
        pkceMethod: 'S256',
        token: tokens.getAccess() ?? '',
        refreshToken: tokens.getRefresh() ?? '',
        checkLoginIframe: false,
        onLoad: 'check-sso',
      })
      .then((authenticated) => {
        authenticated && keycloak?.token && keycloak?.refreshToken
          ? handleIfAuth(keycloak.token, keycloak.refreshToken)
          : handleIfNotAuth();
      })
      .catch(handleAuthError)
      .finally(() => {
        setState((prev) => ({ ...prev, isDataLoading: false }));
        dispatch(showLoader(false));
      });
  }, [dispatch, handleAuthError, handleIfAuth, handleIfNotAuth]);

  const logIn = useCallback((params?: KeycloakLoginOptions) => {
    localStorage.setItem(
      'lastUpdatedLoginPage',
      window.location.pathname,
    );
    return keycloak.login({ ...{ prompt: 'login' }, ...params });
  }, []);

  const logOut = useCallback(() => {
    return keycloak.logout();
  }, []);

  const getProfile = useCallback(() => {
    return keycloak.loadUserProfile();
  }, []);

  const updateToken = useCallback(() => {
    keycloak
      .updateToken(MIN_VALIDITY_SECONDS)
      .then(() => {
        const { token, refreshToken } = keycloak;
        token && refreshToken
          ? handleIfAuth(token, refreshToken)
          : handleIfNotAuth();
      })
      .catch(() => handleIfNotAuth());
  }, [handleIfAuth, handleIfNotAuth]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        init,
        logIn,
        logOut,
        getProfile,
        updateToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
